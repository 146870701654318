import dashboard from './dashboard';
// import widget from './widget';
// // import application from './application';
// import forms from './forms';
// import elements from './elements';
// import pages from './pages';
// import utilities from './utilities';
// import support from './support';
// import other from './other';

// ==============================|| MENU ITEMS ||============================== //

// const menuItems = () => {
//     return {
//         // items: [dashboard, widget, forms, elements, pages, utilities, support, other]
//         items: [dashboard, elements, pages, utilities, support, other, DynamicMenuItems]
//     };
// }

const menuItems = {
    // items: [dashboard, widget, forms, elements, pages, utilities, support, other]
    items: [dashboard]
};



export default menuItems;
