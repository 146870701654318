/**
 * @module
 * @description 	This file stores the reducers for field module.		
 * @author  Abhijeet Gupta <abhijeet.gupta@appcino.com>
 * @version 1.0
 * @date 01/01/2022
 * <br><br>
 * ****************************************************************

 *  MODIFICATION LOG
 * <table >

 *  <tr><td>Version   </td><td> Developer      </td><td>Date 		    </td><td>Description</td></tr>
 
 *  <tr><td>1.0 	    </td><td>Abhijeet Gupta  </td><td>01/01/2022	</td><td>Initial Creation</td></tr>

 *  </table>
*/

//importing actions
import { SET_TABLE, TABLE_LIST, FIELD_LIST} from "../actions/types";

const isEmpty = require("is-empty");


/** 
 *  @desc intializing the state 
 *  @type {Object} 
 *  @property {Object} table
 *  @property {Array} tables
 *  @property {Array} fields
 *  */
const initialState = {
  table: {},
  tables: [],
  fields:[]
};

/**
 * @function fieldReducer
 * @description Use the initialState as a default value
 * @param {string} state initial state
 * @param {string} action 
 * @returns {Object} return the state 
 */


export default function(state = initialState, action) {
  // The reducer normally looks at the action type field to decide what happens.
  switch (action.type) {
 //for the type SET_TABLE
    case SET_TABLE:
  // return a new state object with the table data
      return {
        ...state,
        table: action.payload
      };
  //for the type FIELD_LIST
    case FIELD_LIST:
  // return a new state object with the field list data
      return {
        ...state,
        fields: action.payload
      };
  //If this reducer doesn't recognize the action type, or doesn't care about this specific action, return the existing state unchanged
    default:
      return state;
  }
}
