import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import jwt_decode from "jwt-decode";
import setAuthToken from "./utils/setAuthToken";

import { setCurrentUser, logoutUser } from "./actions/authActions";
import { Provider } from "react-redux";
// import store from "./store";


//new theme import
import Locales from './ui-component/Locales';
import NavigationScroll from './layout/NavigationScroll';
import RTLLayout from './ui-component/RTLLayout';
import Snackbar from './ui-component/extended/Snackbar';
import ThemeCustomization from './themes';
import { JWTProvider as AuthProvider } from './contexts/JWTContext';
// import { FirebaseProvider as AuthProvider } from "./contexts/FirebaseContext";

// routing
import Routes from './routes';
//end new theme import
import './App.css';

const App = () => (
  <ThemeCustomization>
      {/* RTL layout */}
      <RTLLayout>
          <Locales>
              <NavigationScroll>
                  <AuthProvider>
                      <>
                          <Routes />
                          <Snackbar />
                      </>
                  </AuthProvider>
              </NavigationScroll>
          </Locales>
      </RTLLayout>
  </ThemeCustomization>
);

// class App extends Component {
 

export default App;
