/**
 * @module
 * @description 	This file stores the reducers for permission module.				
 * @author  Abhijeet Gupta <abhijeet.gupta@appcino.com>
 * @version 1.0
 * @date 01/01/2022
 * <br><br>
 * ****************************************************************

 *  MODIFICATION LOG
 * <table >

 *  <tr><td>Version   </td><td> Developer       </td><td>Date 		    </td><td>Description</td></tr>
 
 *  <tr><td>1.0 	    </td><td>Abhijeet Gupta   </td><td>01/01/2022	  </td><td>Initial Creation</td></tr>

 *  </table>
*/

//importing actions
import { SET_TABLE, TABLE_LIST, SET_PERMISSION, FIELD_PERMISSION, PROFILE } from "../actions/types";

const isEmpty = require("is-empty");

/** 
 *  @desc intializing the state 
 *  @type {Object} 
 *  @property {Object} table
 *  @property {Array} tables
 *  @property {Array} tablepermission
 *  @property {Array} fieldpermission
 *  */
const initialState = {
  table: {},
  tables: [],
  tablepermission: [],
  fieldpermission: [],
};

/**
 * @function permissionReducer
 * @description Use the initialState as a default value
 * @param {string} state initial state
 * @param {string} action 
 * @returns {Object} return the state
 */
export default function (state = initialState, action) {
  // The reducer normally looks at the action type field to decide what happens.
  switch (action.type) {
    //for the type SET_PERMISSION
    case SET_PERMISSION:
    // return a new state object with the table permission data
      return {
        ...state,
        tablepermission: action.payload
      };
  //for the type FIELD_PERMISSION
    case FIELD_PERMISSION:
    // return a new state object with the field permission data
      return {
        ...state,
        fieldpermission: action.payload
      };
  //for the type TABLE_LIST
    case TABLE_LIST:
  // return a new state object with the table list data
      return {
        ...state,
        tables: action.payload,
      };
  //for the type PROFILE
    case PROFILE:
  // return a new state object with the profile data
      return {
        ...state,
        profile: action.payload,
      };
  //If this reducer doesn't recognize the action type, or doesn't care about this specific action, return the existing state unchanged
    default:
      return state;
  }
}
