/**
 * @module
 * @desc 	This files contains all the validation rule of Client-side<br>
          validate() - validate takes the formData and returns the error
				
 * @author Nishi Singh <nishis@appcino.com>
 * @version 1.0
 * @date 07/04/2022
 * <br><br>
 * ****************************************************************

 *  MODIFICATION LOG
 * <table >

 *  <tr><td>Version   </td><td> Developer     </td><td>Date 		 </td><td>Description</td></tr>
 
 *  <tr><td>1.0 	</td><td>Nishi             </td><td>09/03/2022	</td><td>Initial Creation</td></tr>

 *  </table>
*/
//importing the validator package
import DateUtil from "./DateUtil"
const Validator = require("validator");

/**
 * @desc This function performs the validation rule and take formData and table as input and returns the errors. <br>
         This function is called from  add-new-patient.js:validateForm ,Edit.js:validateForm ,New.js:validateForm
 * @param {string} formData data of field
 * @param {string} table table data
 * @returns return the errors
 */

export function validate(formData, table) {
  // return true
  let errors = {};
  table.fields.forEach((field) => {
    //check the field is required or not ,then perform the validation
    // console.log("field.name-->", field.name, "---=is Required--", field.isRequired)
    if (field.isRequired) {
      if (
        typeof field.name != "object" &&
        (formData[field.name] === undefined || formData[field.name] === null ||
          Validator.isEmpty(formData[field.name].toString().trim()))
      ) {

        errors[field.name] =
          field.fieldLabel.charAt(0).toUpperCase() +
          field.fieldLabel.slice(1) +
          " can't be empty";
        return;
      } 
    }

    if (formData[field.name]) {
      //check number
      if (field.fieldType === "Number") {
        if (!Validator.isNumeric(formData[field.name].toString().trim())) {
          errors[field.name] =
            field.fieldLabel.charAt(0).toUpperCase() +
            field.fieldLabel.slice(1) +
            " should only accept numeric value ";
          return;
        }
      }

      //check string
      if (
        (field.fieldType === "String" || field.fieldType === "LongText") &&
        typeof field.name !== "string"
      ) {
        errors[field.name] =
          field.fieldLabel.charAt(0).toUpperCase() +
          field.fieldLabel.slice(1) +
          " must be string";

        return;
      }
      //check date
      if (field.fieldType === "Date") {
        //check valid date
        if (!Validator.isISO8601(formData[field.name])) {
          errors[field.name] =
            field.fieldLabel.charAt(0).toUpperCase() +
            field.fieldLabel.slice(1) +
            " is invalid";

          return;
        }
        // check min value
        if (
          !Validator.isISO8601(formData[field.name]) ||
          (field.min !== undefined && field.min !== null &&
            Validator.isBefore(formData[field.name], field.min))
        ) {
          const d = new Date(field.min);
          let text = d.toLocaleDateString("en-US");
          // let text = d.getFullYear()+'-' + (d.getMonth()+1) + '-'+d.getDate();
          errors[field.name] =
            field.fieldLabel.charAt(0).toUpperCase() +
            field.fieldLabel.slice(1) +
            " cannot be lesser than " +
            text;
          return;
        }
      }
      //check date
      if (field.fieldType === "Date-Object") {
        if(!formData[field.name]){
          return
        }
        //check valid date

        if(typeof(formData[field.name]) =='object'){
          if(!formData[field.name]['date']){
            console.log("Date-Object" + formData[field.name]['date'])
            errors[field.name] =
            field.fieldLabel.charAt(0).toUpperCase() +
            field.fieldLabel.slice(1) +
            " is Required";

          return;
          }
          if(formData[field.name]['date'] =='Invalid Date'){
            console.log("enter imn formData[field.name]['date'] =='Invalid Date'")
           
            errors[field.name] =
              field.fieldLabel.charAt(0).toUpperCase() +
              field.fieldLabel.slice(1) +
              " is invalid";

            return;
          }
          if (!Validator.isISO8601(DateUtil.getDateValue(formData[field.name]))) {
            
  
            errors[field.name] =
              field.fieldLabel.charAt(0).toUpperCase() +
              field.fieldLabel.slice(1) +
              " is invalid";

            return;
          }
          // check min value
          if (
            !Validator.isISO8601(DateUtil.getDateValue(formData[field.name])) ||
            (field.min !== undefined && field.min !== null &&
              Validator.isBefore(DateUtil.getDateValue(formData[field.name]), field.min))
          ) {
            const d = new Date(field.min);
            let text = d.toLocaleDateString("en-US");
            // let text = d.getFullYear()+'-' + (d.getMonth()+1) + '-'+d.getDate();
            errors[field.name] =
              field.fieldLabel.charAt(0).toUpperCase() +
              field.fieldLabel.slice(1) +
              " cannot be lesser than " +
              text;
            return;
          }
        }else{
          if (!Validator.isISO8601(formData[field.name])) {
            errors[field.name] =
              field.fieldLabel.charAt(0).toUpperCase() +
              field.fieldLabel.slice(1) +
              " is invalid";
  
            return;
          }
          // check min value
          if (
            !Validator.isISO8601(formData[field.name]) ||
            (field.min !== undefined && field.min !== null &&
              Validator.isBefore(formData[field.name], field.min))
          ) {
            const d = new Date(field.min);
            let text = d.toLocaleDateString("en-US");
            // let text = d.getFullYear()+'-' + (d.getMonth()+1) + '-'+d.getDate();
            errors[field.name] =
              field.fieldLabel.charAt(0).toUpperCase() +
              field.fieldLabel.slice(1) +
              " cannot be lesser than " +
              text;
            return;
          }
        }
      }

      var regX =/^[a-z\–\-\(\)_\.\s]+$/i
      // var regX =/^[a-zA-Z]+$/

      console.log("186 -->", regX.test(formData[field.name]))

      //table specific validations
      if (
        [
          "patient__c",
          "physician__c",
          "medicalcondition__c",
          "medicalevaluation__c",
        ].includes(table.tableName)
      ) {
        //check name allowed characters: a to z, A to Z, - , – , (, ), _ , . , space

        // console.log(field.name, '---', regX.test(formData[field.name]))
        // console.log("198 -->",formData[field.name].match(/^[a-z\–\-\(\)_\.\s]+$/i))

        // field.name === "name" && (formData[field.name].match(/^[a-z\–\-\(\)_\.\s]+$/i) ==undefined || formData[field.name].match(/^[a-z\–\-\(\)_\.\s]+$/i)==null )

        if (
          field.name === "name" && (!regX.test(formData[field.name]))
        ) {
          errors[field.name] =
            field.fieldLabel.charAt(0).toUpperCase() +
            field.fieldLabel.slice(1) +
            " should only accept valid name";
          return;
        }
        //check medical condition and medical evaluation for op notes

      if (["opnotes__c"].includes(table.tableName)) {
        let medicalData = {
          medical_condition: "medical_evaluation",
          second_medical_condition: "second_medical_evaluation",
          third_medical_condition: "third_medical_evaluation",
        };

        if (Object.keys(medicalData).includes(field.name)) {
          if (
            formData[medicalData[field.name]] === undefined ||
            formData[medicalData[field.name]] === null ||
            formData[medicalData[field.name]] === ""
          ) {
            const FieldName = table.fields.find(
              (_field) => _field.name === medicalData[field.name]
            );

            errors[medicalData[field.name]] =
              FieldName.fieldLabel.charAt(0).toUpperCase() +
              FieldName.fieldLabel.slice(1) +
              " is required";

            return;
          }
        }
      }
      }

      //Field specify validations
      //check weight and height
      if (
        ["weight", "height"].includes(field.name) &&
        !Validator.isNumeric(formData[field.name].toString().trim())
      ) {
        errors[field.name] =
          field.fieldLabel.charAt(0).toUpperCase() +
          field.fieldLabel.slice(1) +
          " should only accept numeric value ";
        return;
      } else if (
        ["weight", "height"].includes(field.name) &&
        formData[field.name] <= 0
      ) {
        errors[field.name] =
          field.fieldLabel.charAt(0).toUpperCase() +
          field.fieldLabel.slice(1) +
          " should only accept numeric value greater than 0";
        return;
      } else if (
        !["weight", "height"].includes(field.name) &&
        formData[field.name] < 0
      ) {
        errors[field.name] =
          field.fieldLabel.charAt(0).toUpperCase() +
          field.fieldLabel.slice(1) +
          " should only accept numeric value greater than 0";
        return;
      }

      // to allow for future date remove patients_1st_case_date 
      if (
        ["date_of_birth", "patients_1st_case_date", "date_of_death"].includes(
          field.name
        )
      ) {
        if (formData[field.name].date) {
          // console.log(field.name + "-- 267 -->", formData[field.name]+ "=====" + formData[field.name]['date'])
          // console.log("typeof --> " , typeof formData[field.name]['date'])

          formData[field.name].date = typeof formData[field.name]['date'] =='object' ? formData[field.name]['date'].toISOString() : formData[field.name]['date']
          if (Validator.isAfter(formData[field.name].date)) {
            errors[field.name] =
              field.fieldLabel.charAt(0).toUpperCase() +
              field.fieldLabel.slice(1) +
              " cannot be in future";
            return;
          }
        } else {
          //  return
        }
      }
      
        //check patient date and date of death is greater than DOB
      if (
          ["date_of_death", "patients_1st_case_date"].includes(field.name) &&
          DateUtil.getDateValue(formData[field.name]) < DateUtil.getDateValue((formData["date_of_birth"]))
      ) {
          const d = new Date(formData["date_of_birth"].date);
          let text = d.toLocaleDateString("en-US");

          errors[field.name] =
            field.fieldLabel.charAt(0).toUpperCase() +
            field.fieldLabel.slice(1) +
            " cannot be lesser than " +
            text;

        return;
      }
      //check future dates
      if (
        ["date_of_birth", "patients_1st_case_date"].includes(field.name) &&
        (Validator.isAfter(typeof formData[field.name] =='object' ? ('date' in formData[field.name] ? formData[field.name].date : formData[field.name] ) : formData[field.name]))
      ) {
        errors[field.name] =
          field.fieldLabel.charAt(0).toUpperCase() +
          field.fieldLabel.slice(1) +
          " cannot be in future";
        return;
      }

      //check phone number
      const phoneRegex = /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g;
      if (
        ["phone_number", "phone_number_2"].includes(field.name) &&
        !phoneRegex.test(formData[field.name])
        // !Validator.isMobilePhone(formData[field.name], "any", {strictMode: true})
      ) {
        errors[field.name] =
          field.fieldLabel.charAt(0).toUpperCase() +
          field.fieldLabel.slice(1) +
          " should only accept valid phone number";
        return;
      }

      //check email
      if (
        field.name === "email_address" &&
        !Validator.isEmail(formData[field.name])
      ) {
        errors[field.name] =
          field.fieldLabel.charAt(0).toUpperCase() +
          field.fieldLabel.slice(1) +
          " should only accept valid email address";
        return;
      }

      //check zip code
      if (
        field.name === "zip_code" &&
        !Validator.isPostalCode(formData[field.name].toString().trim(), "any")
      ) {
        errors[field.name] =
          field.fieldLabel.charAt(0).toUpperCase() +
          field.fieldLabel.slice(1) +
          " should only accept valid zip code";
        return;
      }
    }
  });

  return errors;
}

export function validateValues(value,name , type){

  var regX =/^[a-z\–\-\(\)_\.\s]+$/i
  
  console.log("value-->", value)
  console.log("type-->", type)
  let errors = {};
   //check email
   if (type=== "email" && !Validator.isEmail(value)) {
    errors[name] =" should only accept valid email address";

    console.log(" should only accept valid email address");
    return " should only accept valid email address yeloo";
  
  }

    //check number
    if (type === "Number") {
      if (!Validator.isNumeric(value.toString().trim())) {
        errors[name] =
          name.charAt(0).toUpperCase() +
          name.slice(1) +
          " should only accept numeric value ";
        return   name.charAt(0).toUpperCase() + name.slice(1) + " should only accept numeric value ";
      }
    }


    console.log("type-->", type)
    console.log("typeof value-->", typeof value)
    //check string
    if (
      (type === "text" ) && (!regX.test(value))
    ) {
      errors[name] =
        name.charAt(0).toUpperCase() +
        name.slice(1) +
        " must be string";
        console.log(" must be string");

      return   name.charAt(0).toUpperCase() + name.slice(1) +" must be string";
    }


  console.log("errors--.", errors)
  // return errors;

}
