import React, { useEffect, useState } from "react";
import AnimateButton from "../../ui-component/extended/AnimateButton";
import axios from "../../utils/axios";

import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormGroup,
  FormHelperText,
  Grid,
  Rating,
  Stack,
  TextField,
  Typography,
  Select,
  InputLabel,
} from "@mui/material";
import SubCard from "../../ui-component/cards/SubCard";
import { validate } from "../../utils/Validate";


import { openSnackbar } from "../../store/slices/snackbar";
import { useDispatch, useSelector } from '../../store/index';


const Campaign = (props) => {
  const [ratingResponse, setRatingResponse] = useState({});
  const [errors, setErrors] = useState({});
  const dispatch = useDispatch();

  const [questionsList, setQuestionsList] = useState([]);
  const [campaignBoxOpen, setCampaignBoxOpen] = useState(false);
  const [apiHit, setApiHit] = useState(0);
  const [disableSubmitButton, setDisableSubmitButton] =  useState(false);
  const [checkSchduledCampaign, setCheckSchduledCampaign] = useState(false);
  // const [disableSubmitButton, setdisableSubmitButton] = useState(false);
  const [campaignData, setCampaignData] = useState();
  useEffect(() => {
    axios
      .get("/api/dynamic-table/get-sechduled-campaign")
      .then((res) => {
       
        setCampaignData(res.data.success_data);
      })
      .catch((err) => {
        console.log("err->", err);
      });
  }, []);


  useEffect(() => {
    let todays_date = new Date().toJSON().slice(0, 10);

    // if(campaignData && campaignData.campaign_data && campaignData.campaign_data._id ){
    //   // alert('hi')
    //   console.log("campaignData.campaign_data._id-->", campaignData.campaign_data._id)
    //   axios
    //     .get("/api/dynamic-table/check-feedback-submitted/"+ campaignData.campaign_data._id)
    //     .then((res) => {
          
    //       if(res.data.feedback){
    //         let feedbackData = JSON.stringify({
    //           user_id: props.user.id,
    //           is_feedback_submitted: true,
    //           campaign_id:campaignData.campaign_data._id
    //         });
    //         localStorage.setItem("feedbackSubmitted", feedbackData);
    //       }
    //       // else{
    //       //   console.log("calling from 73 ")
    //       //   setCampaignBoxOpen(true);
    //       // }

    //       // setCampaignData(res.data.success_data);
    //     })
    //     .catch((err) => {
    //       console.log("err->", err);
    //     });
    // }
  }, [campaignData]);

  

  useEffect(() => {
    console.log("skippedData campaignData 88 wala-->", campaignData);

    if(campaignData && campaignData.campaign_data){
      
    let skippedData = localStorage.getItem("skipedFeedbackData");
    let feedbackSubmitted = localStorage.getItem("feedbackSubmitted");

    let todays_date = new Date().toJSON().slice(0, 10);
    console.log("skippedData--->", skippedData)
    console.log("skippedData--->", campaignData.campaign_data)
    console.log("skippedData--->", campaignData.campaign_data._id)


      if (skippedData) {
        let skippedDataJson =  JSON.parse(skippedData)
        console.log("skippedDataJson-->", skippedDataJson);
        let skippedDate = JSON.parse(skippedData)["feedback_skipped_date"];
        let campaign_id = JSON.parse(skippedData)["campaign_id"];
        let campaign_ids = JSON.parse(skippedData)["campaign_ids"];
        let skipped_by_user = JSON.parse(skippedData)["user_id"];

        // console.log("campaign_id-->", campaign_id)
        // console.log("campaign_ids-->", campaign_ids)
        let skipped_key = Object.keys(skippedDataJson)
          if(skipped_key.includes(['campaign_'+campaignData.campaign_data.id])){
            console.log("hey its working   ")
          }


          // if (skippedDate == todays_date && props.user.id == skipped_by_user &&(campaign_id == campaignData.campaign_data._id || campaign_ids(campaignData.campaign_data._id))) {
          if (skippedDate == todays_date && props.user.id == skipped_by_user &&(campaign_ids.includes(campaignData.campaign_data._id))) {

            setCampaignBoxOpen(false);
          }else{
            console.log("skippedData Ye yha se aaya h-->", skippedDate ,"==", todays_date ,'-&&-', props.user.id ,"==", skipped_by_user ,'-&&-', campaign_id ,"==", campaignData.campaign_data._id)
            setCampaignBoxOpen(true);
    
          }
      }else{
        setCampaignBoxOpen(true);
      }
      
      // if(feedbackSubmitted){

        
        
      //   let campaign_id = JSON.parse(feedbackSubmitted)["campaign_id"];
      //   let submitted_by_user = JSON.parse(feedbackSubmitted)["user_id"];
      //   let is_feedback_submitted = JSON.parse(feedbackSubmitted)["is_feedback_submitted"];
        

      //   if (is_feedback_submitted  && props.user.id == submitted_by_user && campaign_id == campaignData.campaign_data._id) {
      //     setCampaignBoxOpen(false);
      //   }else{
      //     console.log("skippedData Ye yha se aaya h 137 -->")//, skippedDate ,"==", todays_date ,'-&&-', props.user.id ,"==", skipped_by_user ,'-&&-', campaign_id ,"==", campaignData.campaign_data._id)

      //     setCampaignBoxOpen(true);
  
      //   }



      // }else{
      //   setCampaignBoxOpen(true);

      // }
    }


  }, [campaignData]);

  const CheckSkippedDate = () => {
    let skippedDate = localStorage.getItem("skipedFeedbackData");

    console.log("skippedDate:", skippedDate);
  };

  useEffect(() => {
    // let skippedData = localStorage.getItem("skipedFeedbackData");
    // let feedbackSubmitted = localStorage.getItem("feedbackSubmitted");

    // let todays_date = new Date().toJSON().slice(0, 10);
    // console.log("skippedData--->", skippedData)
    // if (skippedData) {
    //   console.log("");
    //   let skippedDate = JSON.parse(skippedData)["feedback_skipped_date"];
    //   let campaign_id = JSON.parse(skippedData)["campaign_id"];
    //   let logged_in_user = JSON.parse(skippedData)["user_id"];
    //   console.log("skippedDate --->", skippedDate)
    //   console.log("todays_date --->", todays_date)
    //   console.log("skippedDate == todays_date --->", skippedDate == todays_date)

    //   if(campaignData){
    //   if (skippedDate == todays_date && props.user.id == logged_in_user && campaign_id == campaignData.campaign_data.id) {
    //     setCampaignBoxOpen(false);
    //   }else{
    //     setCampaignBoxOpen(true);

    //   }
    //   }
    // }else{
    // }
    // console.log("not submitted: -->", campaignData)

    // if(feedbackSubmitted){

      
      
    //   let campaign_id = JSON.parse(feedbackSubmitted)["campaign_id"];
    //   let logged_in_user = JSON.parse(feedbackSubmitted)["user_id"];
    //   let is_feedback_submitted = JSON.parse(feedbackSubmitted)["is_feedback_submitted"];
    //   console.log("props.user.id == logged_in_user-->", props.user.id ,"==", logged_in_user,"---", campaignData)
    //   if(campaignData){

    //     if (is_feedback_submitted  && props.user.id == logged_in_user && campaign_id == campaignData.campaign_data.id) {
    //       setCampaignBoxOpen(false);
    //     }else{
    //       setCampaignBoxOpen(true);
  
    //     }
    //   }

    // }




  },[campaignBoxOpen])

  useEffect(()=>{
    console.log("CampaignBoxOpen-->", campaignBoxOpen)
  },[campaignBoxOpen])

  const handlePreferencesDialogClose = () => {};

  const handleSubmitFeedback = () => {
    console.log("ratingResponse-->", ratingResponse);

    let data = {} 
    
    data['formData'] = {...ratingResponse}

    data['campaign_id'] = campaignData.campaign_data._id

    console.log("data-->", data);

   
    axios
      .post("/api/dynamic-table/submit-feedback", data)
      .then((res) => {
        console.log(res);
        if (res.data && res.data.save_response) {
          // setQuestionsList(res.data.success_data);
          let feedbackData = JSON.stringify({
            user_id: props.user.id,
            is_feedback_submitted: true,
            campaign_id:campaignData.campaign_data._id,
            ['campaign_'+campaignData.campaign_data._id]: campaignData.campaign_data._id,

          });

          console.log("feedbackData-->", feedbackData)
          localStorage.setItem("feedbackSubmitted", feedbackData);
          setCampaignBoxOpen(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onChange = (e, que_id, que_name) => {

    if(e.target.value){

      setRatingResponse({
        ...ratingResponse,
        [e.target.id]: { name: que_name, response: e.target.value, question: e.target.id},
      });
    }else{

      delete ratingResponse[e.target.id]
      setRatingResponse({
        ...ratingResponse,
        
      });
    }

    console.log("ratingResponse-->", ratingResponse);
  };

  const handleRatingChange = (e, ques, name) => {
    setRatingResponse({
      ...ratingResponse,
      [ques]: { name: name, response: e.target.value, question: ques },
    });

    console.log("ratingResponse-->", ratingResponse);
  };

  useEffect(() => {
    console.log('disableSubmitButton->', disableSubmitButton)
  },[disableSubmitButton])
  useEffect(() => {
    console.log("ratingResponse-->", ratingResponse);

    let keys = Object.keys(ratingResponse)
    console.log("keys-->", keys.length)
    if(keys.length > 0){
      console.log("hello keys")
      // setDisableSubmitButton(false)
    }else{
      // setDisableSubmitButton(true)

    }

  }, [ratingResponse]);

  const handleDialogBoxClose = () => {
    let todays_date = new Date().toJSON().slice(0, 10);


    let feedbackSkippedData = localStorage.getItem("skipedFeedbackData");

    let skipedFeedbackData =  JSON.parse(feedbackSkippedData)


    
    let submitted_campaigns = []
    if(skipedFeedbackData){

      let campaign_ids = skipedFeedbackData['campaign_ids'] ? JSON.parse(skipedFeedbackData['campaign_ids']) :[]
      submitted_campaigns = [...campaign_ids]
    }
    submitted_campaigns.push(campaignData.campaign_data._id)
      let feedbackData = JSON.stringify({
        user_id: props.user.id,
        is_feedback_submitted: true,
        campaign_id:campaignData.campaign_data._id,
        campaign_ids:JSON.stringify(submitted_campaigns),
        ['campaign_'+campaignData.campaign_data._id]: campaignData.campaign_data._id,

      });


      console.log("feedbackData-->", feedbackData)

    let skipFeedbackData = JSON.stringify({
      user_id: props.user.id,
      feedback_skipped: true,
      feedback_skipped_date: todays_date,
      campaign_ids:JSON.stringify(submitted_campaigns),
      campaign_id: campaignData.campaign_data._id,
      ['campaign_'+campaignData.campaign_data._id]: campaignData.campaign_data._id,
    });
    localStorage.setItem("skipedFeedbackData", skipFeedbackData);
    setCampaignBoxOpen(false);
  };

  const validate = (formData, table) => {
    let errors = {};

    table.forEach((field) => {
      // console.log("from 190 field-->", field)
      //check the field is required or not ,then perform the validation
      // console.log("field.name-->", field.name, "---=is Required--", field.isRequired)
      if (field.required==='Yes') {
        if(formData[field._id] === undefined || formData[field._id] === null){
          // console.log("from 198-->", field.name)
          errors[field.name] = " is Required";
        }
      }
    })

    console.log("errors-->", errors)
    return errors
    setErrors({...errors});

  }

 const  validateForm  = () => {
    /** @type {Boolean} @desc This is used to check the form is valid or not.  */ 
    let formIsValid = false; 
    /** @type {Object} @desc This formdata stores the data of field.  */ 
    let formData = {...ratingResponse};
   
    /** @type {Object} @desc This table stores the metafield of table.  */ 
    const  table  =  campaignData.questions;
    //calling the validate function in error,setting the error and checking if error occurs or not,and by this finding the form is valid or not.
     /** @type {Object} @desc This errors calls the validate function  which contains the data of field and table   */  
    
    const errors_test = validate(formData, table);

    console.log("errors errors_test", errors_test);

    // setErrors({...errors_test});
    // this.setState({ errors: errors });
   
    if (Object.keys(errors_test).length === 0) {
      console.log("te")
      formIsValid = true;
    }
    return formIsValid;
          }

  const handleSubmit = (e) =>{
    e.preventDefault();
    if(validateForm() ===false){
      console.log('error nhi h')
     
      dispatch(
        openSnackbar({
            open: true,
            message: 'Some fields are required',
            variant: 'alert',
            alert: {
                color: 'error'
            },
            close: false
        })
        );
        return false 

    console.log("ennmpsmdfpsdnfio")

    }else{


      setDisableSubmitButton(true)
      let data = {} 
    
    data['formData'] = {...ratingResponse}

    data['campaign_id'] = campaignData.campaign_data._id

    console.log("data-->", data);



    
    let feedbackSubmittedData = localStorage.getItem("feedbackSubmitted");

    let feedbackSubmitted =  JSON.parse(feedbackSubmittedData)




 
     
   
    axios
      .post("/api/dynamic-table/submit-feedback", data)
      .then((res) => {
        console.log(res);
        if (res.data && res.data.save_response) {
          // setQuestionsList(res.data.success_data);



          let submitted_campaigns = []
          if(feedbackSubmitted){
      
            let campaign_ids = feedbackSubmitted['campaign_ids'] ? feedbackSubmitted['campaign_ids'] :[]
            submitted_campaigns = [...campaign_ids]
            submitted_campaigns.push(campaignData.campaign_data._id)
            }
            let feedbackData = JSON.stringify({
              user_id: props.user.id,
              is_feedback_submitted: true,
              campaign_id:campaignData.campaign_data._id,
              campaign_ids:JSON.stringify(submitted_campaigns),
              ['campaign_'+campaignData.campaign_data._id]: campaignData.campaign_data._id,
      
            });
      

            console.log("feedbackData-->", feedbackData)




          // let feedbackData = JSON.stringify({
          //   user_id: props.user.id,
          //   is_feedback_submitted: true,
          //   campaign_id:campaignData.campaign_data._id
          // });
          localStorage.setItem("feedbackSubmitted", feedbackData);
          setCampaignBoxOpen(false);
          setDisableSubmitButton(false)




          dispatch(
            openSnackbar({
                open: true,
                message: 'Feedback Saved Successfully',
                variant: 'alert',
                alert: {
                    color: 'success'
                },
                close: false
            })
            );
        }
      })
      .catch((err) => {
        console.log(err);
      });
    }



    console.log("Heyylo")
  }
  return (
    <div>
      
      {campaignData && (
        <Dialog
          open={campaignBoxOpen}
          // onClose={handleDialogBoxClose}
          fullWidth={true}
          maxWidth={"md"}
          //  scroll={"body"}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          <DialogTitle>
            {campaignData &&
              campaignData.campaign_data &&
              campaignData.campaign_data.name}
          </DialogTitle>
          {campaignData &&
          campaignData.questions &&
          campaignData.questions.length > 0 ? (
            <>
            <form onSubmit={handleSubmit}>
              <DialogContent>
                <SubCard >
                  <Grid container spacing={1} >
                    {campaignData.questions.map((question, i) => {
                      switch (question.type) {
                        case "Text":
                          return (
                            <Grid
                              item
                              xs={12}
                              lg={12}
                              sx={{ width: "100%", margin: "16px 0px" }}
                              
                            >
                              <FormGroup fullWidth >
                                {/* <Typography variant="subtitle1"> */}
                                  {question.name}{" "}
                                 
                                  <InputLabel className={question.required==='Yes' ? "fieldRequired" : ""}>
                                    {question.name}
                                  </InputLabel>
                                {/* </Typography> */}
                                <div className="input-field">
                                  <TextField
                                    fullWidth
                                    onChange={(e) =>
                                      onChange(e, question._id, question.name)
                                    }
                                    data-id={question._id}
                                    name={question._id}
                                    id={question._id}
                                    type={question.type.trim()}
                                    placeholder={
                                      question.name.charAt(0).toUpperCase() +
                                      question.name.slice(1)
                                    }
                                  />
                                </div>
                              </FormGroup>
                            </Grid>
                          );
                          break;
                        case "Long Text":
                          return (
                            <Grid
                              item
                              xs={12}
                              lg={12}
                              sx={{ width: "100%", margin: "16px 0px" }}
                            >
                              <FormGroup fullWidth>
                                <InputLabel className={question.required==='Yes' ? "fieldRequired" : ""}>
                                    {question.name}
                                </InputLabel>
                                <FormGroup fullWidth>
                                  <div className="input-field">
                                    <TextField
                                      row="14"
                                      multiline
                                      onChange={(e) =>
                                        onChange(e, question._id, question.name)
                                      }
                                      data-id={question._id}
                                      id={question._id}
                                      type={question.type.trim()}
                                      placeholder={
                                        question.name.charAt(0).toUpperCase() +
                                        question.name.slice(1)
                                      }
                                      fullWidth
                                    />
                                  </div>
                                </FormGroup>
                              </FormGroup>
                            </Grid>
                          );
                          break;
                        case "Picklist":
                          return (
                            <Grid
                              item
                              xs={12}
                              lg={12}
                              sx={{ width: "100%", margin: "16px 0px" }}
                            >
                              <FormGroup fullWidth>
                                <InputLabel className={question.required==='Yes' ? "fieldRequired" : ""}>
                                    {question.name}
                                </InputLabel>
                                <div className="input-field">
                                  <FormGroup fullWidth>
                                    <Select
                                      fullWidth
                                      native
                                      onChange={(e) =>
                                        onChange(e, question._id, question.name)
                                      }
                                      id={question._id}
                                      name={question._id}
                                      listData={question.listData}
                                      fullwidth
                                    >
                                      <option value="">
                                        Please select an option
                                      </option>
                                      {(() => {
                                        if (
                                          question.options != undefined &&
                                          question.options instanceof Array
                                        ) {
                                          return question.options.map(
                                            (option, index) => (
                                              <option
                                                key={option}
                                                value={
                                                  option.value &&
                                                  option.value.trim()
                                                }
                                              >
                                                {option.text &&
                                                  option.text.trim()}
                                              </option>
                                            )
                                          );
                                        } else if (
                                          question.options != undefined &&
                                          typeof question.options == "string"
                                        ) {
                                          return JSON.parse(
                                            question.options
                                          ).map((option, index) => (
                                            <option
                                              key={option}
                                              value={
                                                option.value &&
                                                option.value.trim()
                                              }
                                            >
                                              {option.text &&
                                                option.text.trim()}
                                            </option>
                                          ));
                                        }
                                      })()}
                                    </Select>
                                  </FormGroup>
                                </div>
                              </FormGroup>
                            </Grid>
                          );
                          break;

                        case "Rating":
                          return (
                            <>
                              <Grid item xs={12}>
                                <InputLabel className={question.required==='Yes' ? "fieldRequired" : ""}>
                                    {question.name}
                                </InputLabel>

                                <Stack spacing={1}>
                                  <Rating
                                    name="size-medium"
                                    defaultValue={0}
                                    precision={0.5}
                                    onChange={(e) => {
                                      handleRatingChange(
                                        e,
                                        question._id,
                                        question.name
                                      );
                                    }}
                                  />
                                </Stack>
                              </Grid>
                            </>
                          );
                          break;

                        default:
                          break;
                      }
                    })}
                  </Grid>
                </SubCard>
              </DialogContent>
              <DialogActions>
                <Grid container spacing={2} sx={{ p: 0, pr: 4 }}>
                  <Grid item sm zeroMinWidth></Grid>
                  <Grid item>
                    <AnimateButton>
                      <Button
                        variant="outlined"
                        onClick={() => {
                          handleDialogBoxClose();
                        }}
                      >
                        {" "}
                        Skip For Now{" "}
                      </Button>
                    </AnimateButton>
                  </Grid>
                  <Grid item>
                    <AnimateButton>

                      <Button
                        variant="contained"
                        type="submit"
                        disabled={disableSubmitButton}
                        // onClick={(e) => handleSubmitFeedback(e)}
                      >
                        Save
                      </Button>
                    </AnimateButton>
                  </Grid>
                </Grid>
              </DialogActions>
              </form>
            </>
          ) : (
            <>
              <CircularProgress />
            </>
          )}
        </Dialog>
      )}
    </div>
  );
};

export default Campaign;
