export const LOGOUT = "LOGOUT";
export const LOGIN = "LOGIN";

export const GET_ERRORS = "GET_ERRORS";
export const USER_LOADING = "USER_LOADING";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const SET_LANGUAGES = "SET_LANGUAGES";
export const SET_COUNTRIES = "SET_COUNTRIES";
export const SET_INDUSTRIES = "SET_INDUSTRIES";
export const SET_DOMAINS = "SET_DOMAINS";
export const SET_USER_CHATBOTS = "SET_USER_CHATBOTS";
export const PUBLIC_CHATBOT = "PUBLIC_CHATBOT";
export const PUBLIC_ROOM = "PUBLIC_ROOM";
export const ROOM_LIST = "ROOM_LIST";
export const SET_COUNTRY = "SET_COUNTRY";
export const SET_LANGUAGE = "SET_LANGUAGE";
export const SET_INDUSTRY = "SET_INDUSTRY";
export const SET_DOMAIN = "SET_DOMAIN";
export const SET_TABLE = "SET_TABLE";
export const SET_DEPLOYMENT_TABLE = "SET_DEPLOYMENT_TABLE";
export const SET_INTERVENTION_TABLE = "SET_INTERVENTION_TABLE";
export const TABLE_LIST = "TABLE_LIST";
export const TAB_LIST = "TAB_LIST";
export const FIELD_LIST = "FIELD_LIST";
export const SET_FIELD = "SET_FIELD";
export const SET_DYNAMIC_VIEW= "SET_DYNAMIC_VIEW";
export const DYNAMIC_VIEW_LIST= "DYNAMIC_VIEW_LIST";
export const PATIENT_FOLLOWUPS_LIST= "PATIENT_FOLLOWUPS_LIST";
export const DYNAMIC_VIEW_FIELD= "DYNAMIC_VIEW_FIELD";
export const DYNAMIC_VIEW_DETAIL_DATA = "DYNAMIC_VIEW_DETAIL_DATA";
export const DYNAMIC_VIEW_DETAIL_DATA_DEPLOYMENT = "DYNAMIC_VIEW_DETAIL_DATA_DEPLOYMENT";
export const DYNAMIC_VIEW_DETAIL_DATA_INTERVENTION = "DYNAMIC_VIEW_DETAIL_DATA_INTERVENTION";
export const RESET = "RESET";



export const DYNAMIC_VIEW_LIST_DATA= "DYNAMIC_VIEW_LIST_DATA";
export const DYNAMIC_VIEW_REFERENCE_LIST_DATA= "DYNAMIC_VIEW_LIST_DATA";
export const LIST_VIEWS= "LIST_VIEWS";
export const PAGE_LAYOUTS= "PAGE_LAYOUTS";
export const USER_LIST = "USER_LIST";
export const USER_INFO = "USER_INFO";

export const PROFILE_LIST= "PROFILE_LIST";
export const SET_PERMISSION= "SET_PERMISSION";
export const PERMISSION_lIST= "SET_PERMISSION";
export const FIELD_PERMISSION= "FIELD_PERMISSION";
export const PERMISSION_LIST= "PERMISSION_LIST";
export const IMPORT_CSV_DATA= "IMPORT_CSV_DATA";
export const SUGGESTION_LIST= "SUGGESTION_LIST";

export const DYNAMIC_VIEW_LIST_TABLEDATA= "DYNAMIC_VIEW_LIST_TABLEDATA";


export const PATIENT_QUESTIONNAIRE= "PATIENT_QUESTIONNAIRE";
export const PATIENT_QUESTIONNAIRE_FOLLOWUPS= "PATIENT_QUESTIONNAIRE_FOLLOWUPS";


export const REPORT_LIST= "REPORT_LIST";
export const PIVOT_REPORT_LIST= "PIVOT_REPORT_LIST";
export const RUN_REPORT_SINGLE = "RUN_REPORT_SINGLE";
export const REPORT_SINGLE = "REPORT_SINGLE";
export const REPORT_PIVOT = "REPORT_PIVOT";
export const REPORT_SINGLE_PIVOT = "REPORT_SINGLE_PIVOT";
export const PIVOT_REPORT_TABLE_DATA = "PIVOT_REPORT_TABLE_DATA";
export const DASHBOARD_CATEGORIES= "DASHBOARD_CATEGORIES";

export const CHART_DATA= "CHART_DATA";
export const PROFILE = "PROFILE";
// export const TABLE_LIST = "TABLE_LIST";


export const GET_PATIENT_LIST = "GET_PATIENT_LIST";
export const SHOW_LOADER = "SHOW_LOADER";
export const TABLE_LIST_LOADING = "TABLE_LIST_LOADING";

export const PATIENT_FOLLOWUPS_SUBSCRIPTION = "PATIENT_FOLLOWUPS_SUBSCRIPTION";
export const DASHBOARD_REPORT_LIST= "DASHBOARD_REPORT_LIST";


export const MY_DASHBOARD_REPORT_LIST= "MY_DASHBOARD_REPORT_LIST";

export const SEND_DOCUSIGN_ENVELOPE="SEND_DOCUSIGN_ENVELOPE"

export const DYNAMIC_MEDICAL_RELATION="DYNAMIC_MEDICAL_RELATION"


