/**
 * @module
 * @description 	This file stores the reducers for the view module.			
 * @author  Abhijeet Gupta <abhijeet.gupta@appcino.com>
 * @version 1.0
 * @date 01/01/2022
 * <br><br>
 * ****************************************************************

 *  MODIFICATION LOG
 * <table >

 *  <tr><td>Version   </td><td> Developer        </td><td>Date 		    </td><td>Description</td></tr>
 
 *  <tr><td>1.0 	    </td><td>Abhijeet Gupta    </td><td>01/01/2022	</td><td>Initial Creation</td></tr>

 *  </table>
*/

//importing Action
import { PATIENT_FOLLOWUPS_LIST, SET_TABLE, TABLE_LIST, DYNAMIC_VIEW_FIELD, DYNAMIC_VIEW_LIST, DYNAMIC_VIEW_DETAIL_DATA, DYNAMIC_VIEW_LIST_DATA, DYNAMIC_VIEW_REFERENCE_LIST_DATA, LIST_VIEWS, PAGE_LAYOUTS, PATIENT_QUESTIONNAIRE, DYNAMIC_VIEW_LIST_TABLEDATA, SET_DEPLOYMENT_TABLE, SET_INTERVENTION_TABLE, DYNAMIC_VIEW_DETAIL_DATA_DEPLOYMENT, DYNAMIC_VIEW_DETAIL_DATA_INTERVENTION,PATIENT_QUESTIONNAIRE_FOLLOWUPS,GET_PATIENT_LIST, SHOW_LOADER,PATIENT_FOLLOWUPS_SUBSCRIPTION, DYNAMIC_MEDICAL_RELATION} from "../actions/types";

const isEmpty = require("is-empty");

/** 
 *  @desc intializing the state 
 *  @type {Object} 
 *  @property {Object} table
 *  @property {Array} tables
 *  @property {Array} dynamic_data
 *  @property {Object} dynamic_detail_data
 *  @property {Array} dynamic_list_data
 *  @property {Array} dynamic_list_tabledata
 *  @property {Array} dynamic_reference_list_data
 *  @property {Array} list_views
 *  @property {Array} page_layouts
 *  @property {Array} patientQuestionnaire
 *  */
const initialState = {
  table: {},
  tables: [],
  dynamic_data:[],
  dynamic_detail_data:{},
  dynamic_list_data:[],
  dynamic_list_tabledata:[],
  dynamic_reference_list_data:[],
  list_views:[],
  page_layouts:[],
  patientQuestionnaire:[],
  showLoader: true
};

/**
 * @function viewReducer
 * @description Use the initialState as a default value
 * @param {string} state initial state
 * @param {string} action 
 * @returns {Object} return the state
 */


const viewReducer = (state = initialState, action) => {
  // The reducer normally looks at the action type field to decide what happens.
  switch (action.type) {
    //for the type SET_TABLE
    case SET_TABLE:

   let x = {...state, table: action.payload}
    // console.log("69 x ->>", x)
  //  let y = {...state.table, table: action.payload}

  //   let yData = {...y}

  //   if(Object.keys(y).length > 1 ){
  //     delete yData['table']
      
  //     x = {...state, table: {...yData}}

  //   }
   // return a new state object with the table data

  //  return {...x}


      return {
  // that has all the existing state data
        ...state,
  //value of table is stored in the action.payload field
        table: action.payload
      };

    //for the type SET_DEPLOYMENT_TABLE
    case SET_DEPLOYMENT_TABLE:
    // return a new state object with the deployment table data
      return {
        ...state,
        //value of table is stored in the action.payload field
        deploymenttable: action.payload
      };
    //for the type SET_INTERVENTION_TABLE
    case SET_INTERVENTION_TABLE:
    // return a new state object with the interventiontable data
      return {
        ...state,
        //value of table is stored in the action.payload field
        interventiontable: action.payload
      };
    //for the type TABLE_LIST
    case TABLE_LIST:
    // return a new state object with the table list data
      return {
        ...state,
        //value of tables is stored in the action.payload field
        tables: action.payload
      };
    //for the type DYNAMIC_VIEW_LIST
    case DYNAMIC_VIEW_LIST:
    // return a new state object with the dynamic data
      return {
        ...state,
      //value of dynamic_data is stored in the action.payload field
        dynamic_data: action.payload,
        showLoader: false
      };
  //for the type DYNAMIC_VIEW_DETAIL_DATA
    case DYNAMIC_VIEW_DETAIL_DATA:
    // return a new state object with the dynamic detail data
      return {
      //update the state with the new data
        ...state,
      //value of dynamic_detail_data is stored in the action.payload field
        dynamic_detail_data: action.payload
      };
    //for the type DYNAMIC_VIEW_DETAIL_DATA_DEPLOYMENT
    case DYNAMIC_VIEW_DETAIL_DATA_DEPLOYMENT:
    // return a new state object with the dynamic detail data deployment
      return {
    //update the state with the new data
        ...state,
    //value of dynamic_detail_data deployment is stored in the action.payload field
        dynamic_detail_data_deployment: action.payload
      };
    //for the type VIEW_DETAIL_DATA_INTERVENTION
    case DYNAMIC_VIEW_DETAIL_DATA_INTERVENTION:
    // return a new state object with the dynamic detail data intervention
      return {
        ...state,
    //value of dynamic_detail_data intervention is stored in the action.payload field
        dynamic_detail_data_intervention: action.payload
      };
  //for the type DYNAMIC_VIEW_LIST_DATA_TABLEDATA
    case DYNAMIC_VIEW_LIST_TABLEDATA:
    // return a new state object with the dynamic list table data
      return {
        ...state,
    //value of dynamic_list_tabledata is stored in the action.payload field
        dynamic_list_tabledata: action.payload
      };
  //for the type DYNAMIC_VIEW_LIST_DATA
    case DYNAMIC_VIEW_LIST_DATA:
  // return a new state object with the dynamic list data
      const fieldId = action.fieldId
      const showLoading = action.showLoading
  
      // console.log("148 fieldId-->", fieldId,"--- showLoading---", action)
        const _state = {...state};
        //Finding that field ID in available fields
        /**
         * @todo 
         * 1. Manage cases like there may noe exists such below deep structure
         * 2. In case field id is not provided in action or no such field found in available list of field then old style of passing it in to DYNAMIC_VIEW_LIST_DATA should continue
         *  */
        // console.log(fieldId,"---action.payload-->", action.payload)
        // console.log("targetField 166-->", targetField)
        if(fieldId){
        // console.log("159 fieldId-->", fieldId,"--- showLoading---", showLoading)
  
          let targetField = state.table.fields && state.table.fields.find(field => field._id === fieldId);
          
          if(!targetField){

            console.log(" type err action.payload-->", action.payload)
            return{
              ..._state,
              // dynamic_list_data: action.payload
            }
          }
          targetField['fieldId'] = fieldId;
          targetField['showLoading'] = showLoading;
  
      //  console.log("162 fieldId-->", fieldId,"--- showLoading---", showLoading)
        if(showLoading){
          targetField['showLoading'] = showLoading;
            
          return {
            ..._state,
                dynamic_list_data: action.payload
          };  
        }
        targetField['showLoading'] = showLoading;
  
        // console.log("179 fieldId-->", fieldId,"--- showLoading---", showLoading, "===targetField===", targetField)
  
        targetField['list'] = action.payload.list;
              
         
          // targetField['showLoading'] = showLoading;
          // targetField['listing'] = action.payload.list;
  
        // console.log("targetField-->", targetField)
  
          return {
            ..._state,
            dynamic_list_data: action.payload
          };  
        }
        
        return {
          ...state,
        //value of dynamic_list_data is stored in the action.payload field
          dynamic_list_data: action.payload
        };
   //for the type DYNAMIC_VIEW_REFERENCE_LIST_DATA
    case DYNAMIC_VIEW_REFERENCE_LIST_DATA:
    // return a new state object with the dynamic reference list data
      return {
        ...state,
    //value of dynamic_reference_list_data is stored in the action.payload field
        dynamic_reference_list_data: action.payload
      };
  //for the type LIST_VIEWS
    case LIST_VIEWS:
    // return a new state object with the list views
      return {
        ...state,
        list_views: action.payload
      };
  //for the type PAGE_LAYOUTS
    case PAGE_LAYOUTS:
  // return a new state object with the page layouts
      return {
        ...state,
        page_layouts: action.payload
      };
  //for the type PATIENT_LIST
    case GET_PATIENT_LIST:
  // return a new state object with the patient list
      return {
        ...state,
        patient_list: action.payload
      };
  //for the type PATIENT_QUESTIONNAIRE
    case PATIENT_QUESTIONNAIRE:
  // return a new state object with the patient questionnaire
      return {
        ...state,
        patientQuestionnaire: action.payload
      };
  //for the type PATIENT_QUESTIONNAIRE_FOLLOWUPS
    case PATIENT_QUESTIONNAIRE_FOLLOWUPS:
  // return a new state object with the  questionnaire followups
      return {
        ...state,
        questionnairefollowups: action.payload
      };
  //for the type PATIENT_FOLLOWUPS_LIST
    case PATIENT_FOLLOWUPS_LIST:
  // return a new state object with the tables
        return {
          ...state,
          tables: action.payload
        };
  //for the type SHOW_LOADER
    case SHOW_LOADER:
    // return a new state object with the show loader
      return {
        ...state,
        showLoader: action.payload
      };
  //for the type PATIENT_FOLLOWUPS_SUBSCRIPTION
      case PATIENT_FOLLOWUPS_SUBSCRIPTION:
        console.log(" -->", {
          ...state,
          patientSubscription: action.payload
        });
    // return a new state object with the patient followups subscription
        return{
          ...state,
          patientSubscription: action.payload
        };

         //for the type DYNAMIC_VIEW_LIST
    case DYNAMIC_MEDICAL_RELATION:
      // return a new state object with the dynamic data
        return {
          ...state,
        //value of dynamic_data is stored in the action.payload field
          dynamic_medical_relation: action.payload,
          showLoader: false
        };
  // If this reducer doesn't recognize the action type, or doesn't care about this specific action, return the existing state unchanged
    default:
      return state;
  }
}

export default viewReducer;