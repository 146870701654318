
/**
 * @module
 * @description This file contains the authorization method which set the HTTP Authorization header for requests sent with axios from a React app to an API when the user is authenticated.<br>
                setAuthToken()-returns the Set token to Auth header
 * @author Abhijeet Gupta <abhijeet.gupta@appcino.com>
 * @version 1.0
 * @date 01/01/2022
 * <br><br>
 * ****************************************************************

 *  MODIFICATION LOG
 * <table >

 *  <tr><td>Version   </td><td> Developer      </td><td>Date 		    </td><td>Description</td></tr>
 
 *  <tr><td>1.0 	    </td><td>Abhijeet Gupta  </td><td>01/01/2022	</td><td>Initial Creation</td></tr>

 *  </table>
 

*/ 

//import the axios
import axios from "../utils/axios";

 /**
@desc This function is called from : authAction.js:loginUser,logoutUser,treatmentinfo
@param {string} token token Auth token
@returns {string} returns the Set token to Auth header

*/

const setAuthToken = token => {
  if (token) {
    // Apply authorization token to every request if logged in
    axios.defaults.headers.common["Authorization"] = token;
    // axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  } else {
    // Delete auth header
    delete axios.defaults.headers.common["Authorization"];
  }
};

//export the module as setAuthToken
export default setAuthToken;
