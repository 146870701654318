// material-ui
import React, { useEffect, useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import { Button, Container, Grid, Typography, Stack } from "@mui/material";
import Link from "@mui/material/Link";

// project imports
import AppBar from "../../../ui-component/extended/AppBar";
import MainCard from "../../../ui-component/cards/MainCard";
import { gridSpacing } from "../../../store/constant";

// assets
import headerBackground from "../../../assets/images/landing/header-bg.jpg";

import ContentCopyIcon from "@mui/icons-material/ContentCopy";
// assets
import mailImg from "../../../assets/images/landing/img-contact-mail.svg";
import CsnFooter from "../landing/CsnFooter";
import { openSnackbar } from "../../../store/slices/snackbar";
import { useDispatch, useSelector } from "../../../store/index";
import AnimateButton from "../../../ui-component/extended/AnimateButton";

const HeaderWrapper = styled("div")(({ theme }) => ({
  backgroundImage: `url(${headerBackground})`,
  backgroundSize: "100% 600px",
  backgroundAttachment: "fixed",
  backgroundRepeat: "no-repeat",
  textAlign: "center",
  paddingTop: 30,
  [theme.breakpoints.down("md")]: {
    paddingTop: 0,
  },
}));

// ============================|| SAAS PAGES - PRIVCY POLICY ||============================ //

const ConfirmPage = () => {
  const theme = useTheme();

  return (
    <>
      <HeaderWrapper>
        <AppBar />
        <Container>
        <Grid container justifyContent="center" spacing={gridSpacing} sx={{pb:5}}>
                    <Grid item sm={10} md={7} sx={{ mt: { md: 12.5, xs: 2.5 }, mb: { md: 5, xs: 2.5 } }}>
                        <Grid container spacing={gridSpacing}>
                            <Grid item xs={12}>
                                <Typography
                                    variant="h1"
                                    color="white"
                                    component="div"
                                    sx={{
                                        fontSize: '3rem',
                                        fontWeight: 900,
                                        lineHeight: 1.4,
                                        [theme.breakpoints.down('md')]: { fontSize: '1.8125rem', marginTop: '80px' }
                                    }}
                                >
                                    Successfully Submitted
                                </Typography>
                            </Grid>
                            
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <MainCard elevation={4} border={false} boxShadow shadow={4} sx={{ mb: 5 }}>
                            <Stack spacing={2} sx={{ textAlign: 'center' }}>
                                
                                <Typography variant="h3">Thank You For Choosing Our Services</Typography>
                                
                                <Typography variant="subtitle1">
                                We have received your Business Associte Agreement, As Our Data Provider Verify your Agreement You can use our Services
                                </Typography>
                               
                                {/* <AnimateButton>
                                    <Button
                                        variant="contained"
                                        sx={{ my: 3, ml: 1 }}
                                    >
                                        <Link to="/login" style={{textDecoration: 'none', color: 'white'}}>
                                        Login
                                        </Link>
                                    </Button>
                                </AnimateButton> */}
                              
                            </Stack>
                        </MainCard>
                    </Grid>
                </Grid>
        </Container>
      </HeaderWrapper>
      <CsnFooter />
    </>
  );
};

export default ConfirmPage;
