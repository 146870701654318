import { SET_LANGUAGES, SET_COUNTRIES, SET_INDUSTRIES, SET_DOMAINS, TAB_LIST, PROFILE_LIST, IMPORT_CSV_DATA } from "../actions/types";

const isEmpty = require("is-empty");

const initialState = {
  languages: [],
  countries: [],
  industries: [],
  domains: [],
  tabs: []
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_LANGUAGES:
      return {
        ...state,
        languages: action.payload
      };
    case SET_COUNTRIES:
      return {
        ...state,
        countries: action.payload
      };
    case SET_INDUSTRIES:
      return {
        ...state,
        industries: action.payload
      };
    case SET_DOMAINS:
      return {
        ...state,
        domains: action.payload
      };
    case TAB_LIST:
      return {
        ...state,
        tabs: action.payload
      };
    case PROFILE_LIST:
      return {
        ...state,
        profiles: action.payload
      };
    case IMPORT_CSV_DATA:
      return {
        ...state,
        table: action.payload
      };
    default:
      return state;
  }
}
