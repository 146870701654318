import { memo, useEffect, useRef } from 'react';

// material-ui
import { Typography } from '@mui/material';
import { useDispatch, useSelector } from '../../../../store/index';
import {getAllTabs} from "../../../../actions/dataActions";
import { getUsersProfile } from '../../../../actions/authActions';
// project imports
import NavGroup from './NavGroup';
import { updateMenuItems } from "../../../../store/slices/menu"
// assets
import { IconDashboard, IconDeviceAnalytics } from '@tabler/icons';

// constant
const icons = {
    IconDashboard,
    IconDeviceAnalytics
};
// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = () => {
    // const [tabState, tabDispatch] = useReducer('reportReducer');
    const dispatch = useDispatch();
    const { tabs } = useSelector((state) => state.data);
    const authState  = useSelector((state) => state.auth);
    const {menuItem: sliceMenuItem} = useSelector((state) => state.menu);
    const menuItem = useRef();
    const navItems = useRef([]);
    useEffect(()=>{
        dispatch(getAllTabs());
        dispatch(getUsersProfile(authState &&  authState.user && authState.user.profileid));
    },[]);
    useEffect(() => {
        menuItem.current = JSON.parse(JSON.stringify(sliceMenuItem));
    }, [sliceMenuItem])
    useEffect(()=>{
        //check if any list related to dynamic-table is already present
        if(tabs && tabs.length > 0 && (menuItem?.current?.items?.length === 1 || navItems.current.length === 0)) {
            const tabMenu =  {
                id: 'tabs',
                title: "Menu Items",
                type: 'group',
                children: []
            };
            const _orderedTabs = tabs.sort((a,b) => a.order-b.order);
            //first add Active
            tabMenu.children.push( ..._orderedTabs.filter(tab => tab.isActive).map((tab, i) => ({
                id: tab.TableMetaDataId.prefix,
                title: tab.name,
                type: 'item',
                url: "/dynamic-table/" +tab.TableMetaDataId.prefix +"/list",
                breadcrumbs: true
            })));
            
            //Add feature menu items at 3rd and 4th position respectively
            tabMenu.children.push(...[
                {
                    id: 'FollowUps',
                    title: 'FollowUps',
                    type: 'item',
                    url: "/followups",
                    breadcrumbs: true
                },
                {
                    id: 'Reports',
                    title: 'Reports',
                    type: 'item',
                    url: "/pivotreport",
                    breadcrumbs: true
                }
            ]);
            //Add more items
            if(_orderedTabs.filter(tab => !tab.isActive).length > 1){
                tabMenu.children.push({
                    id: 'more_items',
                    title: "More Items",
                    type: 'collapse',
                    // icon: icons.IconTools,
                    children: _orderedTabs.filter(tab => !tab.isActive).map((tab, i) => ({
                        id: tab.TableMetaDataId.prefix,
                        title: tab.name,
                        type: 'item',
                        url: "/dynamic-table/" +tab.TableMetaDataId.prefix +"/list",
                        breadcrumbs: true
                    }))
                });
            } else {
                tabMenu.children.push(..._orderedTabs.filter(tab => !tab.isActive).map((tab, i) => ({
                    id: tab.TableMetaDataId.prefix,
                    title: tab.name,
                    type: 'item',
                    url: "/dynamic-table/" +tab.TableMetaDataId.prefix +"/list",
                    breadcrumbs: true
                })));
            }
    
            // if tabs menu already exists from earlier iteration. then override
            const _tabsMenuIndex = menuItem.current.items.findIndex(item => item.id === "tabs");
            if(_tabsMenuIndex !== -1){
                //remove current tabMenu to replace with newly prepared tabMenu
                menuItem.current.items.splice(_tabsMenuIndex, 1);
            } 
    
            const _dashboardMenuIndex =  menuItem.current.items.findIndex(item => item.id === "dashboard");
    
    
           if(_dashboardMenuIndex !== -1){
    
                //remove current tabMenu to replace with newly prepared tabMenu
                menuItem.current.items[_dashboardMenuIndex].children =[
                                    {
                                        id: 'csn',
                                        title: "CSN",
                                        type: 'item',
                                        url: '/dashboard/csn',
                                        icon: icons.IconDeviceAnalytics,
                                        breadcrumbs: false
                                    }
                                ] ;
                
    
                                if(authState && authState.user && authState.user.profileid!="60182b81abce9a67179ee6e7"){
                                    menuItem.current.items[_dashboardMenuIndex].children.push
                                    ({
                                        id: 'default',
                                        title: "My Dashboard",
                                        type: 'item',
                                        url: '/dashboard/default',
                                        icon: icons.IconDeviceAnalytics,
                                        breadcrumbs: false
                                        })
                                }
    
            } 
    
            menuItem.current.items.push(tabMenu);
            dispatch(updateMenuItems(menuItem.current));
            navItems.current = menuItem.current.items.map((item) => {
                switch (item.type) {
                    case 'group':
                        return <NavGroup key={item.id} item={item} />;
                    default:
                        return (
                            <Typography key={item.id} variant="h6" color="error" align="center">
                                Menu Items Error
                            </Typography>
                        );
                }
            });
        }
        // },[tabs]);
        
    },[tabs, sliceMenuItem, dispatch, authState]);
    
    //console.log("Last menu item",menuItem.items[menuItem.items.length-1]);

    return <>{navItems.current}</>;
};

export default memo(MenuList);
