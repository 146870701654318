import { SET_DOMAIN } from "../actions/types";

const isEmpty = require("is-empty");

const initialState = {
  domain: {},
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_DOMAIN:
      return {
        ...state,
        domain: action.payload
      };
    default:
      return state;
  }
}
