import { Outlet } from 'react-router-dom';

// project imports
// import Customization from '../Customization';

// ==============================|| MINIMAL LAYOUT ||============================== //

const MinimalLayout = () => {
    console.log("reached MinimalLayout");
    return (
    <>
        <Outlet />
        {/* <Customization /> */}
    </>
);
    }

export default MinimalLayout;
