/**
 * @module
 * @description 	This file stores the reducers for the report module.				
 * @author  Abhijeet Gupta <abhijeet.gupta@appcino.com>
 * @version 1.0
 * @date 01/01/2022
 * <br><br>
 * ****************************************************************

 *  MODIFICATION LOG
 * <table >

 *  <tr><td>Version   </td><td> Developer     </td><td>Date 		 </td><td>Description</td></tr>
 
 *  <tr><td>1.0 	    </td><td>Abhijeet Gupta  </td><td>01/01/2022	</td><td>Initial Creation</td></tr>

 *  </table>
*/

//importing actions
import {
  REPORT_LIST,
  REPORT_PIVOT,
  REPORT_SINGLE,
  RUN_REPORT_SINGLE,
  CHART_DATA,
  SUGGESTION_LIST,
  PIVOT_REPORT_LIST,
  DASHBOARD_REPORT_LIST,
  REPORT_SINGLE_PIVOT,
  PIVOT_REPORT_TABLE_DATA,
  MY_DASHBOARD_REPORT_LIST,
  DASHBOARD_CATEGORIES
} from "../actions/types";

/** 
 *  @desc intializing the state 
 *  @type {Object} 
 *  @property {Object} report_single
 *  @property {Object} run_report_single
 *  @property {Array}  report_list
 *  @property {Array}  chartData
 *  */
const initialState = {
  report_single: {},
  run_report_single: {},
  report_list: [],
  chartData: []
};


/**
 * @function reportReducer
 * @description Use the initialState as a default value
 * @param {string} state initial state
 * @param {string} action 
 * @returns {Object} return the state
 */
export default function (state = initialState, action) {
  // The reducer normally looks at the action type field to decide what happens.
  switch (action.type) {
    //for the type REPORT_SINGLE
    case REPORT_SINGLE:
    // return a new state object with the report single data and run report single data
      return {
        ...state,
        report_single: action.payload,
        run_report_single: undefined,
      };
  //for the type PIVOT_REPORT_LIST
    case PIVOT_REPORT_LIST:
  // return a new state object with the pivot report list data,report pivot ,data count ,report  single pivot and pivot report table data
      return {
        ...state,
        pivot_report_list: action.payload,
        report_pivot: undefined,
        data_count: undefined,
        report_single_pivot: undefined,
        pivot_report_table_data: undefined,
      };
  //for the type DASHBOARD_REPORT_LIST
    case MY_DASHBOARD_REPORT_LIST:
  // return a new state object with the pivot report list data,report pivot ,data count ,report  single pivot and pivot report table data
      return {
        ...state,
        default_dashboard_report_list: action.payload,
        report_pivot: undefined,
        data_count: undefined,
        report_single_pivot: undefined,
        pivot_report_table_data: undefined,
      };
  //for the type DASHBOARD_REPORT_LIST
    case DASHBOARD_REPORT_LIST:
  // return a new state object with the pivot report list data,report pivot ,data count ,report  single pivot and pivot report table data
      return {
        ...state,
        dashboard_report_list: action.payload,
        report_pivot: undefined,
        data_count: undefined,
        report_single_pivot: undefined,
        pivot_report_table_data: undefined,
      };
  //for the type REPORT_PIVOT
    case REPORT_PIVOT:
  // return a new state object with the report pivot data AND data count
      return {
        ...state,
        report_pivot: action.payload.dynamicData,
        data_count: action.payload.data_count,
      };
    
      //for the type REPORT_SINGLE_PIVOT
    case REPORT_SINGLE_PIVOT:
  // return a new state object with the report single pivot data,data count and report pivot.
      return {
        ...state,
        report_single_pivot: action.payload,
        report_pivot: undefined,
        data_count: undefined,
      };
  //for the type PIVOT_REPORT_TABLE_DATA
    case PIVOT_REPORT_TABLE_DATA:
  // return a new state object with the pivot report table data.
      return {
        ...state,
        pivot_report_table_data: action.payload,
      };
  //for the type  RUN_REPORT_SINGLE
    case RUN_REPORT_SINGLE:
  // return a new state object with the run report single data.
      return {
        ...state,
        run_report_single: action.payload,
      };
    //for the type REPORT_LIST
    case REPORT_LIST:
//return a new state object with the report list ,report single and run report single data.
    return {
        ...state,
        report_list: action.payload,
        report_single: undefined,
        run_report_single: undefined,
      };
  //for the type CHART_DATA
    case CHART_DATA:
//return a new state object with the chart data.
      return {
        ...state,
        chartData: action.payload,
      };
  //for the type SUGGESTION_LIST
    case SUGGESTION_LIST:
//return a new state object with the suggestion list data.
      return {
        ...state,
        suggestions: action.payload,
      };
    //Distinct Dashboard categories
    case DASHBOARD_CATEGORIES:
      //return a new state object with the suggestion list data.
            return {
              ...state,
              dashboard_categories: action.payload,
            };
      //If this reducer doesn't recognize the action type, or doesn't care about this specific action, return the existing state unchanged
    default:
      return state;
  }
}
