// material-ui
import { styled } from '@mui/material/styles';
import { Grid, Container, Typography, TextField, Button } from '@mui/material';

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
// project imports
// import Customization from 'layout/Customization';
import { gridSpacing } from '../../../store/constant';
import ModeCommentOutlinedIcon from '@mui/icons-material/ModeCommentOutlined';
import DraftsIcon from '@mui/icons-material/Drafts';import MapOutlinedIcon from '@mui/icons-material/MapOutlined';

// import backgroundVideo from "./background.mp4"


const SecondWrapper = styled('div')(({ theme }) => ({
    paddingTop: 160,
    [theme.breakpoints.down('md')]: {
        paddingTop: 60
    }
}));

// =============================|| LANDING MAIN ||============================= //

const CsnFooter = () => {
    return (
    <>
        <SecondWrapper style={{ background:'#1e88e5', color:"white", padding:'4rem 0'}}>
            <Container >
                <Grid container spacing={gridSpacing} >
                    <Grid item xs={12} lg={4} md={6}>
                        <Typography variant="h3" style={{color: '#fff', borderBottom: '1px solid', marginBottom: '8px', padding: '8px 0'}} >About Regenatrak</Typography>
                        <Typography variant="p" style={{    margin:"0",
    fontSize: "0.875rem",
    fontWeight: "400",
    lineHeight: "1.334em",
    fontFamily: 'Roboto,sans-serif',
    flexGrow: 1,
    textAlign: "left"
}}>Regenatrak is an affordable medical software developed and provided by the 501c3 non-profit, International Cell Surgical Society. It’s a low-code alternative to spreadsheets, and complicated enterprise systems. Track, manage and automate critical patient follow-up with greater efficiency, speed, and ease. </Typography>
                    </Grid>
                    <Grid item xs={12} lg={4} md={6}>   
                    <Typography variant="h3" style={{color: '#fff', borderBottom: '1px solid', marginBottom: '8px', padding: '8px 0'}} > Contact Info</Typography>
                    <Typography variant="h5" style={{color: '#fff', fontSize: '.875rem' , paddingTop:'8px' }}><DraftsIcon /> info@regenatrack.com</Typography>
                        <Typography variant="h5" style={{color: '#fff', fontSize: '.875rem' , paddingTop:'8px' }}><ModeCommentOutlinedIcon />(760) 464-0181</Typography>
                        <Typography variant="h5" style={{color: '#fff', fontSize: '.875rem' , paddingTop:'8px' }}><MapOutlinedIcon/> 73700 Dinah Shore Dr, Ste 301 Palm Desert, CA 92211</Typography>
                    </Grid>
                    <Grid item xs={12} lg={4} md={6}>
                    <Typography variant="h3" style={{color: '#fff', borderBottom: '1px solid', marginBottom: '8px', padding: '8px 0'}} > Request A Free Demo</Typography>
                    
                    <TextField fullwidth placeholder="Name" style={{marginBottom: '8px',width: '100%', }}/>
                    <TextField fullwidth placeholder="Email Address" style={{marginBottom: '8px',width: '100%', }}/>
                    <TextField fullwidth placeholder="Company Name" style={{marginBottom: '8px',width: '100%', }}/>
                    <Button Type="submit" variant="primary">Send</Button>
                    
                    </Grid>
                </Grid>
            </Container>
        </SecondWrapper>
    </>
)};

export default CsnFooter;
