/**
 * @module
 * @description 	This file stores the reducers for auth module.
 * @author  Abhijeet Gupta <abhijeet.gupta@appcino.com>
 * @version 1.0
 * @date 01/01/2022
 * <br><br>
 * ****************************************************************

 *  MODIFICATION LOG
 * <table >

 *  <tr><td>Version   </td><td> Developer      </td><td>Date 		   </td><td>Description</td></tr>
 
 *  <tr><td>1.0 	    </td><td>Abhijeet Gupta  </td><td>01/01/2022	</td><td>Initial Creation</td></tr>

 *  </table>
*/

//importing actions
import { SET_CURRENT_USER, USER_LOADING, USER_LIST, PERMISSION_LIST, RESET, USER_INFO, LOGOUT, LOGIN,SEND_DOCUSIGN_ENVELOPE } from "../actions/types";

const isEmpty = require("is-empty");


/** 
 *  @desc intializing the state 
 *  @type {Object} 
 *  @property {boolean} isAuthenticated
 *  @property {boolean} isAdmin
 *  @property {Object} editingUser
 *  @property {Array} users
 *  @property {boolean} loading
 *  */
const initialState = {
  isAuthenticated: true,
  isAdmin: false,
  editingUser: {},
  users:[],
  loading: false,
  isInitialized : false
};
// const initialState = {
//   isAuthenticated: false,
//   isAdmin: false,
//   editingUser: {},
//   users:[],
//   loading: false
// };

/**
 * @function authReducer
 * @description Use the initialState as a default value
 * @param {string} state initial state
 * @param {string} action 
 * @returns {Object} return the state
 */
const authReducer = (state = initialState, action) => {
  // The reducer normally looks at the action type field to decide what happens.
  switch (action.type) {

    case SEND_DOCUSIGN_ENVELOPE:
      console.log("SET_CURRENT_USER reducer called;;", action.payload);

      // return a new state object with the reset data
      return {
        ...state,
        isAuthenticated: !isEmpty(action.payload),
        isAdmin: !isEmpty(action.payload)?action.payload.isAdmin:false,
        user: action.payload,
        isInitialized : true,
        data: action.payload
      };
  //for the type SET_CURRENT_USER
    case SET_CURRENT_USER:
      console.log("SET_CURRENT_USER reducer called;;", action.payload);
    // return a new state object with the user data, isAuthenticated and isAdmin
      return {
        ...state,
        isAuthenticated: !isEmpty(action.payload),
        isAdmin: !isEmpty(action.payload)?action.payload.isAdmin:false,
        user: action.payload,
        isInitialized : true
      };
    //for the type USER_LIST
    case USER_LIST:
  // return a new state object with the user list data
      return {
        ...state,
        users: action.payload
      };
    
      //for the type RESET
    case RESET:
  // return a new state object with the reset data
      return {
        ...state,
        reset: action.payload
      };
//for the type PERMISSION_LIST
    case PERMISSION_LIST:
  // return a new state object with the table profile data
    return {
      ...state,
      tableProfile: action.payload
    };
    //for the type USER_LOADING
    case USER_LOADING:
    // return a new state object with the user loading data
      return {
        ...state,
        loading: true
      };
  //for the type USER_INFO
    case USER_INFO:
    // return a new state object with the editing user data
      return {
        ...state,
        editingUser: action.payload
      };
    case LOGIN: {
        const newState = {
          ...state,
          isAuthenticated : action.payload.isAuthenticated,
          user : action.payload.user,
          isInitialized : true
        };
        return newState;
      }
    case LOGOUT: {
      return {
        ...state,
        isInitialized : true,
        isAuthenticated : false,
        user : null
      };
    }
  //If this reducer doesn't recognize the action type, or doesn't care about this specific action, return the existing state unchanged
    default:
      return state;
  }
}
export default authReducer;
