// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconDashboard, IconDeviceAnalytics } from '@tabler/icons';

// constant
const icons = {
    IconDashboard,
    IconDeviceAnalytics
};

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const dashboard = {
    id: 'dashboard',
    //title: <FormattedMessage id="dashboard" />,
    title: "Dashboards",
    type: 'group',
    children: [
        // {
        //     id: 'csn',
        //     title: "CSN",
        //     type: 'item',
        //     url: '/dashboard/csn',
        //     icon: icons.IconDeviceAnalytics,
        //     breadcrumbs: false
        // }
        // {
        //     id: 'csn',
        //     title: "CSN",
        //     type: 'item',
        //     url: '/dashboard/csn',
        //     icon: icons.IconDeviceAnalytics,
        //     breadcrumbs: false
        // }
    ]
};

export default dashboard;
