import React, { useEffect, useState } from "react";
import AnimateButton from "../../ui-component/extended/AnimateButton";
import axios from "../../utils/axios";
import { styled, useTheme } from "@mui/material/styles";
import headerBackground from "../../assets/images/landing/header-bg.jpg";

import AppBar from "../../ui-component/extended/AppBar";
import MainCard from "../../ui-component/cards/MainCard";
import { gridSpacing } from "../../store/constant";
import { useNavigate } from 'react-router-dom';

import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormGroup,
  FormHelperText,
  Grid,
  Rating,
  Stack,
  TextField,
  Typography,
  Container,
} from "@mui/material";
import SubCard from "../../ui-component/cards/SubCard";
import CsnFooter from "../../views/pages/landing/CsnFooter";
import { Link } from "react-router-dom";

import { useSelector } from "react-redux";

const HeaderWrapper = styled("div")(({ theme }) => ({
  backgroundImage: `url(${headerBackground})`,
  backgroundSize: "100% 600px",
  backgroundAttachment: "fixed",
  backgroundRepeat: "no-repeat",
  textAlign: "center",
  paddingTop: 30,
  [theme.breakpoints.down("md")]: {
    paddingTop: 0,
  },
}));
const PublicCampaign = (props) => {
  const theme = useTheme();

  const navigate = useNavigate();
  const [ratingResponse, setRatingResponse] = useState({});
  const [enableSaveButton, setEnableSaveButton] = useState(false)
  const [questionsList, setQuestionsList] = useState([]);
  const [campaignBoxOpen, setCampaignBoxOpen] = useState(true);
  const [apiHit, setApiHit] = useState(0);
  const [questionnaireId, setQuestionnaireId] = useState()
  const authState = useSelector((state) => state.auth);
  useEffect(() => {
    let pathname = window.location.pathname
    let que = pathname.split('/')
    console.log("que-->", que)
   
    localStorage.setItem('pathname', pathname)
    if (authState && authState.user == null) {
      navigate('/login')
    } else {
      if(que.length>1){
        let que_id = que[2]
        setQuestionnaireId(que_id)
       
      }
    }
  });
  useEffect(()=>{
    console.log("questionnaireId->", questionnaireId)
    fetchData();
  }, [questionnaireId])
  const fetchData = () => {
    if (questionsList.length <= 0 && questionnaireId) {
      console.log("hey 26-->");
      setApiHit(0);
      axios
        .get("/api/dynamic-table/get-feedback-questionnaire/"+questionnaireId)
        .then((res) => {
          console.log(res);
          if (res.data && res.data.success_data) {
            setApiHit(1);
            setQuestionsList(res.data.success_data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handlePreferencesDialogClose = () => {};

  const handleSubmitFeedback = () => {
    console.log("ratingResponse-->", ratingResponse);
    axios
      .post("/api/dynamic-table/submit-feedback", ratingResponse)
      .then((res) => {
        console.log(res);
        if (res.data && res.data.save_response) {
          // setQuestionsList(res.data.success_data);
          let feedbackData = JSON.stringify({
            user_id: authState.user.id,
            is_feedback_submitted: true,
          });
          localStorage.removeItem("pathname");
          localStorage.setItem("feedbackSubmitted", feedbackData);
          setCampaignBoxOpen(false);
          navigate('/dashboard/csn')
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onChange = (e, que_id, que_name) => {
    setRatingResponse({
      ...ratingResponse,
      [e.target.id]: { name: que_name, response: e.target.value },
    });

    console.log("ratingResponse-->", ratingResponse);
  };

  const handleRatingChange = (e, ques, name) => {

    setEnableSaveButton(true)
    setRatingResponse({
      ...ratingResponse,
      [ques]: { name: name, response: e.target.value },
    });

    console.log("ratingResponse-->", ratingResponse);
  };

  useEffect(() => {
    console.log("ratingResponse-->", ratingResponse);
  }, [ratingResponse]);

  const handleDialogBoxClose = () => {
    setCampaignBoxOpen(false);
  };
  return (
    <div>
      <>
        {/* <AppBar /> */}
        <Container>
          <Grid container justifyContent="center" spacing={gridSpacing}>
          
            <Grid item xs={12}>
              <MainCard
                elevation={4}
                border={false}
                boxShadow
                shadow={4}
                sx={{ mb: 3 }}
              >
                <Stack spacing={2} sx={{ textAlign: "left" }}>
                  <Typography variant="h3">Heading</Typography>
                  <hr />

                  {questionsList &&
                  questionsList.questions &&
                  questionsList.questions.length > 0 ? (
                    <>
                      {questionsList.questions.map((question) => {
                        switch (question.type) {
                          case "Text":
                            return (
                              <Grid
                                item
                                xs={12}
                                lg={12}
                                sx={{ width: "100%", margin: "16px 0px" }}
                              >
                                <FormGroup fullWidth>
                                  <Typography variant="h4">
                                    {question.name}{" "}
                                  </Typography>
                                  <div className="input-field">
                                    <TextField
                                      fullWidth
                                      onChange={(e) =>
                                        onChange(e, question._id, question.name)
                                      }
                                      data-id={question._id}
                                      name={question._id}
                                      id={question._id}
                                      type={question.type.trim()}
                                      placeholder={
                                        question.name.charAt(0).toUpperCase() +
                                        question.name.slice(1)
                                      }
                                    />
                                  </div>
                                </FormGroup>
                              </Grid>
                            );
                            break;
                          case "Rating":
                            return (
                              <>
                                <Grid item xs={12}>
                                  <Typography variant="h4">
                                    {question.name}
                                  </Typography>

                                  <Stack spacing={1}>
                                    <Rating
                                      size="large"
                                      defaultValue={0}
                                      precision={0.5}
                                      onChange={(e) => {
                                        handleRatingChange(
                                          e,
                                          question._id,
                                          question.name
                                        );
                                      }}
                                    />
                                  </Stack>
                                </Grid>
                              </>
                            );
                            break;

                          default:
                            break;
                        }
                      })}
                    </>
                  ) : (
                    <></>
                  )}
                <Grid container spacing={2} sx={{ p: 0, pr: 4 }}>
                  <Grid item sm zeroMinWidth></Grid>
                  <Grid item>
                    <AnimateButton>
                      <Button
                        variant="outlined"
                        onClick={() => {
                         localStorage.removeItem("pathname");

                          navigate('/login');
                        }}

                      >
                        {" "}
                        Cancel{" "}
                      </Button>
                    </AnimateButton>
                  </Grid>
                  <Grid item>
                    <AnimateButton>
                      <Button
                        variant="contained"
                        type="button"
                        onClick={(e) => handleSubmitFeedback(e)}
                        disabled={enableSaveButton ==false}
                      >
                        Save
                      </Button>
                    </AnimateButton>
                  </Grid>
                </Grid>
                </Stack>
              </MainCard>
            </Grid>
          </Grid>
        </Container>
      </>
    </div>
  );
};

export default PublicCampaign;
