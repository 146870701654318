import axios from '../utils/axios';

import { GET_ERRORS, SET_CURRENT_USER, USER_LOADING, SET_LANGUAGES, SET_COUNTRIES, SET_INDUSTRIES, SET_DOMAINS, TAB_LIST, PROFILE_LIST,IMPORT_CSV_DATA } from "./types";

// Get all languages
export const getAllLanguages = () => dispatch => {
  axios
    .get("/api/language/all")
    .then(res => {
    	dispatch({ type: SET_LANGUAGES, payload : res.data.languages })
    })
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};

// Get all countries
export const getAllCountries = () => dispatch => {
  axios
    .get("/api/country/all")
    .then(res => {
      dispatch({ type: SET_COUNTRIES, payload : res.data.countries })
    })
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};

// Get all industries
export const getAllIndustries = () => dispatch => {
  axios
    .get("/api/industry/all")
    .then(res => {
      dispatch({ type: SET_INDUSTRIES, payload : res.data.industries })
    })
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};

// Get all domains
export const getAllDomains = () => dispatch => {
  axios
    .get("/api/domain/all")
    .then(res => {
      dispatch({ type: SET_DOMAINS, payload : res.data.domains })
    })
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};

// Get all tabs
export const getAllTabs = () => dispatch => {
  // console.log("getAllTabs called in reportAction.js", Date.now(), axios.defaults.headers.common.Authorization);
  // dispatch({ type: SHOW_LOADER, payload: true });
  // axios.defaults.headers.common.Authorization = "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYxNDQ5NTBmNWUzYTczMzY2NGUwZDFiNiIsIm5hbWUiOiJhZG1pbkBhZG1pbi5jb20iLCJlbWFpbCI6ImFkbWluQGFkbWluLmNvbSIsInByb2ZpbGVpZCI6IjYwMTgyYjgxYWJjZTlhNjcxNzllZTZlNyIsInNob3dfZGV0YWlsX3BvcHVwIjpmYWxzZSwiaWF0IjoxNjU0NzgwODU2LCJleHAiOjE2ODYzMzc3ODJ9.LWxhhhpVGzJwyviNefLgRAPPHWohLz7vxNbrZRWM8Vc";
  axios
    .get("/api/tabs/all")
    .then(res => {
      dispatch({ type: TAB_LIST, payload : res.data.tabs })
    })
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response
      })
    );
};


// Get all profile roll
export const getAllProfile = () => dispatch => {
  axios
    .get("/api/profiles/all")
    .then(res => {
      dispatch({ type: PROFILE_LIST, payload : res.data.profiles })
    })
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response
      })
    );
};


// upload CSV
export const importCsv = () => dispatch => {
  axios
    .get("/api/dynamic-table/upload")
    .then(res => {
      dispatch({ type: IMPORT_CSV_DATA, payload : res.data.csv_data })
    })
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response
      })
    );
};




