import PropTypes from 'prop-types';
import { createContext, useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';


import { openSnackbar } from '../store/slices/snackbar';
// third-party
import { Chance } from 'chance';
import jwtDecode from 'jwt-decode';

// reducer - state management
import { LOGIN, LOGOUT, RESET } from '../store/actions';
import { SET_CURRENT_USER, GET_ERRORS , SEND_DOCUSIGN_ENVELOPE} from '../actions/types'
import setAuthToken from '../utils/setAuthToken';

// project imports
import Loader from '../ui-component/Loader';
import axios from '../utils/axios';
import { useDispatch, useSelector } from '../store/index';

const chance = new Chance();

// constant
const initialState = {
    isAuthenticated: false,
    isAdmin: false,
    editingUser: {},
    users:[],
    loading: false,
    isInitialized : false
  };

const verifyToken = (serviceToken) => {
    if (!serviceToken) {
        return false;
    }
    const decoded = jwtDecode(serviceToken);
     /**
     * In case of no expiry(logged in with "Remember me") exp property do not exists'.
     */
    return decoded.exp ? (decoded.exp > Date.now() / 1000) : true;
};

const setSession = (serviceToken) => {
    if (serviceToken) {
        localStorage.setItem('jwtToken', serviceToken);
        axios.defaults.headers.common.Authorization = serviceToken;
    } else {
        localStorage.removeItem('jwtToken');
        delete axios.defaults.headers.common.Authorization;
    }
};

// ==============================|| JWT CONTEXT & PROVIDER ||============================== //
const JWTContext = createContext(null);

export const JWTProvider = ({ children }) => {


    const navigate = useNavigate()
    //can not use useSelector her  e. for some reason useEffect does not get called in that case
    // const [state, dispatch] = useReducer(authReducer, initialState)
    const state = useSelector((state) => state.auth);
    const dispatch = useDispatch();

// useState
    const [docusignUrl, setDocusignUrl] = useState()

    useEffect(() => {
        const init = async () => {
            try {
                const serviceToken = window.localStorage.getItem('jwtToken');
                if (serviceToken && verifyToken(serviceToken)) {
                    setSession(serviceToken);
                    // const response = await axios.get('/api/account/me');
                    // const { user } = response.data;
                    dispatch({
                        type: LOGIN,
                        payload: {
                            isAuthenticated: true,
                            user : jwtDecode(serviceToken),
                        }
                    });
                } else {
                    dispatch({
                        type: LOGOUT
                    });
                }
            } catch (err) {
                console.error(err);
                dispatch({
                    type: LOGOUT
                });
            }
        };

        init();
    }, []);


    useEffect(()=>{
        console.log("setDocusignUrl-->", docusignUrl)
    }, [docusignUrl])

    const login = async (email, password, rememberMe) => {
        // dispatch(loginUser({email, password}));
        axios
            .post("/api/users/login", {email, password,rememberMe})
            .then(res => {


                
                
                // Set token to localStorage
                const { token } = res.data;
                console.log("ressssssssssssssssssss------>", res.data)
               
                // Decode token to get user data
                const decoded = jwtDecode(token);

                console.log("decoded -------->", decoded)
                // return
                if('url' in decoded['otherDetail'] && ('envelopeStatus' in decoded['otherDetail'] && decoded['otherDetail']['envelopeStatus'].toLowerCase()!='completed')){



                    dispatch(
                        openSnackbar({
                            open: true,
                            message: "We Are Redirecting on Docusing to sign BUSINESS ASSOCIATE AGREEMENT",
                            variant: 'alert',
                            alert: {
                                color: 'success',
                                severity: 'error'
                            },
                            close: false
                        })
                    );

                    decoded['otherDetail'] && setDocusignUrl(decoded['otherDetail']['url'])
               
                    
                    window.location.replace(decoded['otherDetail']['url'])
                    navigate(decoded['otherDetail']['url'], {replace:true})
                    dispatch({
                        type: SEND_DOCUSIGN_ENVELOPE,
                        payload: decoded
                    })
                }
                else if(decoded['otherDetail'] && ('envelopeStatus' in decoded['otherDetail'] &&  decoded['otherDetail']['envelopeDPStatus'] !='completed')){


                    dispatch(
                        openSnackbar({
                            open: true,
                            message: "Business Associate Agreement verification pending",
                            variant: 'alert',
                            alert: {
                                color: 'error',
                                severity: 'error'
                            },
                            close: false
                        })
                    );


                    localStorage.setItem("jwtToken", token);
                    // Set token to Auth header
                    setAuthToken(token);
                    dispatch({
                        type: SET_CURRENT_USER,
                        payload: decoded
                    })

                 
                }
                
                else{
                    localStorage.setItem("jwtToken", token);
                    // Set token to Auth header
                    setAuthToken(token);
                    dispatch({
                        type: SET_CURRENT_USER,
                        payload: decoded
                    })
                }
                // Set current user
                // dispatch(setCurrentUser(decoded));
            })
            .catch(err =>
                {
                    console.log("err->", err)
                    //Can only display one err message at a time
                    if(err && Array.isArray(err)){
                        err = err[0];
                    }
                    //Default error message
                    let errMessage = 'Incorrect Email / Password';
                    // if error object received in format= {field: errorMessage} then get its value to override default error message
                    if(err && typeof err === 'object' && Object.values(err).length === 1){
                        errMessage = Object.values(err)[0];
                    }
                    dispatch(
                        openSnackbar({
                            open: true,
                            message: errMessage,
                            variant: 'alert',
                            alert: {
                                color: 'error',
                                severity: 'error'
                            },
                            close: false
                        })
                    );
                    dispatch({
                    type: GET_ERRORS,
                    payload: err
                    })                    
                }
            );
        // // const response = await axios.post('/api/account/login', { email, password });
        // // const { serviceToken } = response.data;
        // setSession(serviceToken);
        // dispatch({
        //     type: LOGIN,
        //     payload: {
        //         isAuthenticated: true,
        //         user : jwtDecode(serviceToken)
        //     }
        // });
    };

    const register = async (email, password, firstName, lastName) => {
        // todo: this flow need to be recode as it not verified
        const id = chance.bb_pin();
        const response = await axios.post('/api/account/register', {
            id,
            email,
            password,
            firstName,
            lastName
        });
        let users = response.data;

        if (window.localStorage.getItem('users') !== undefined && window.localStorage.getItem('users') !== null) {
            const localUsers = window.localStorage.getItem('users');
            users = [
                ...JSON.parse(localUsers),
                {
                    id,
                    email,
                    password,
                    name: `${firstName} ${lastName}`
                }
            ];
        }

        window.localStorage.setItem('users', JSON.stringify(users));
    };

    const logout = () => {
        setSession(null);
        dispatch({ type: LOGOUT });
    };



    const resetPassword = async (userData) => {

        // console.log("userData"+ userData);

        axios
        .post("/api/usersreset/reset", userData)
        .then(res => {
          alert('Password reset successfully.');
          console.log("175-->", res);
          dispatch({
            type: RESET,
            payload: res.data
          });
        })
        .catch(err =>
            {
            console.log(err)
                dispatch({
                    type: GET_ERRORS,
                    payload: err
                })
            }
        );
    };

    const updateProfile = () => {};

    if (state.isInitialized !== undefined && !state.isInitialized) {
        return <Loader />;
    }
    return (
        <JWTContext.Provider value={{ ...state, login, logout, register, resetPassword, updateProfile }}>{children}</JWTContext.Provider>
    );
};

JWTProvider.propTypes = {
    children: PropTypes.node
};

export default JWTContext;
