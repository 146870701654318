import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// project imports
import useAuth from '../../hooks/useAuth';
import { DASHBOARD_PATH } from '../../config';

// ==============================|| GUEST GUARD ||============================== //

/**
 * Guest guard for routes having no auth required
 * @param {PropTypes.node} children children element/node
 */

const GuestGuard = ({ children }) => {
    const { isAuthenticated } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        // console.log("localStorage data -->", localStorage.getItem('pathname'))
        if (isAuthenticated) {
            // let pathname = localStorage.getItem('pathname');

            // // console.log("pathname-->", pathname)
            // if(pathname){
            //     // console.log("pathnamepathnamepathnamepathname--,", pathname)
            // navigate(pathname, { replace: true });

            // }else{
            //     // console.log("pathname 31--,", pathname)

                navigate(DASHBOARD_PATH, { replace: true });
            // }
        }
    }, [isAuthenticated, navigate]);

    return children;
};

GuestGuard.propTypes = {
    children: PropTypes.node
};

export default GuestGuard;
