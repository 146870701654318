import { lazy } from 'react';
import { useSelector } from 'react-redux';
// import Dashboard from '../components/admin/Dashboard';

// project imports
import MainLayout from '../layout/MainLayout';
import Loadable from '../ui-component/Loadable';
import AuthGuard from '../utils/route-guard/AuthGuard';
import PublicCampaign from '../components/DialogBoxes/PublicCampaign';

// dashboard routing
// const DashboardDefault = Loadable(lazy(() => import('../views/dashboard/Default')));
const DashboardDefault = Loadable(lazy(() => import('../components/admin/MyDashboard')));
const Dashboard = Loadable(lazy(() => import('../components/admin/Dashboard')));
const Settings = Loadable(lazy(() => import('../components/Settings/settings')))
// const DashboardAnalytics = Loadable(lazy(() => import('../views/dashboard/Analytics')));
const FollowupLayout = Loadable(lazy(() => import('../components/FollowUps/FollowupLayout')));
const NewPivotReports = Loadable(lazy(() => import('../components/pivot-report/New')));

const PivotView = Loadable(lazy(() => import('../components/pivot-report/PivotView')));
const BackAdverseView = Loadable(lazy(() => import('../components/pivot-report/BackAdverseView')));
const AdverseEventReport = Loadable(lazy(() => import('../components/pivot-report/AdverseEventReport')));
const PivotPatientViewNew = Loadable(lazy(() => import('../components/pivot-report/PivotPatientViewNew')));
const PivotPatientViewOld = Loadable(lazy(() => import('../components/pivot-report/PivotPatientViewOld')));
const EditPivotReports = Loadable(lazy(() => import('../components/pivot-report/Edit')));
const ListPivot = Loadable(lazy(() => import('../components/pivot-report/ListPivot')));
const AddField = Loadable(lazy(() => import('../components/DynamicField/AddField')));
// const Recurring = Loadable(lazy(() => import("../components/StandardPages/Recurring")));
// const PayPerUse = Loadable(lazy(() => import("../components/StandardPages/PayPerUse")));
const NewReport = Loadable(lazy(() => import('../components/pivot-report/index')));
const EditReport = Loadable(lazy(() => import('../components/pivot-report/EditIndex')));



// questionnaire routes
const PatientQuestionnaireView = Loadable(lazy(() => import('../components/Questionnaire/PatientQuestionnaireView')));

// widget routing
// const WidgetStatistics = Loadable(lazy(() => import('../views/widget/Statistics')));
// const WidgetData = Loadable(lazy(() => import('../views/widget/Data')));
// const WidgetChart = Loadable(lazy(() => import('../views/widget/Chart')));

// application - user social & account profile routing
// const AppUserSocialProfile = Loadable(lazy(() => import('../views/application/users/social-profile')));
// const AppUserAccountProfile1 = Loadable(lazy(() => import('../views/application/users/account-profile/Profile1')));
// const AppUserAccountProfile2 = Loadable(lazy(() => import('../views/application/users/account-profile/Profile2')));
// const AppUserAccountProfile3 = Loadable(lazy(() => import('../views/application/users/account-profile/Profile3')));

// application - user cards & list variant routing
// const AppProfileCardStyle1 = Loadable(lazy(() => import('../views/application/users/card/CardStyle1')));
// const AppProfileCardStyle2 = Loadable(lazy(() => import('../views/application/users/card/CardStyle2')));
// const AppProfileCardStyle3 = Loadable(lazy(() => import('../views/application/users/card/CardStyle3')));
// const AppProfileListStyle1 = Loadable(lazy(() => import('../views/application/users/list/Style1')));
// const AppProfileListStyle2 = Loadable(lazy(() => import('../views/application/users/list/Style2')));

// application - customer routing
// const AppCustomerList = Loadable(lazy(() => import('../views/application/customer/CustomerList')));
// const AppCustomerOrderList = Loadable(lazy(() => import('../views/application/customer/OrderList')));
// const AppCustomerCreateInvoice = Loadable(lazy(() => import('../views/application/customer/CreateInvoice')));
// const AppCustomerOrderDetails = Loadable(lazy(() => import('../views/application/customer/OrderDetails')));
// const AppCustomerProduct = Loadable(lazy(() => import('../views/application/customer/Product')));
// const AppCustomerProductReview = Loadable(lazy(() => import('../views/application/customer/ProductReview')));

// application routing
// const AppChat = Loadable(lazy(() => import('../views/application/chat')));
// const AppKanban = Loadable(lazy(() => import('../views/application/kanban')));
// const AppKanbanBacklogs = Loadable(lazy(() => import('../views/application/kanban/Backlogs')));
// const AppKanbanBoard = Loadable(lazy(() => import('../views/application/kanban/Board')));
// const AppMail = Loadable(lazy(() => import('../views/application/mail')));
// const AppCalendar = Loadable(lazy(() => import('../views/application/calendar')));
// const AppContactCard = Loadable(lazy(() => import('../views/application/contact/Card')));
// const AppContactList = Loadable(lazy(() => import('../views/application/contact/List')));

// application e-commerce pages
// const AppECommProducts = Loadable(lazy(() => import('../views/application/e-commerce/Products')));
// const AppECommProductDetails = Loadable(lazy(() => import('../views/application/e-commerce/ProductDetails')));
// const AppECommProductList = Loadable(lazy(() => import('../views/application/e-commerce/ProductList')));
// const AppECommCheckout = Loadable(lazy(() => import('../views/application/e-commerce/Checkout')));

// forms component routing
// const FrmComponentsTextfield = Loadable(lazy(() => import('../views/forms/components/TextField')));
// const FrmComponentsButton = Loadable(lazy(() => import('../views/forms/components/Button')));
// const FrmComponentsCheckbox = Loadable(lazy(() => import('../views/forms/components/Checkbox')));
// const FrmComponentsRadio = Loadable(lazy(() => import('../views/forms/components/Radio')));
// const FrmComponentsSwitch = Loadable(lazy(() => import('../views/forms/components/Switch')));
// const FrmComponentsAutoComplete = Loadable(lazy(() => import('../views/forms/components/AutoComplete')));
// const FrmComponentsSlider = Loadable(lazy(() => import('../views/forms/components/Slider')));
// const FrmComponentsDateTime = Loadable(lazy(() => import('../views/forms/components/DateTime')));

// forms plugins layout
// const FrmLayoutLayout = Loadable(lazy(() => import('../views/forms/layouts/Layouts')));
// const FrmLayoutMultiColumnForms = Loadable(lazy(() => import('../views/forms/layouts/MultiColumnForms')));
// const FrmLayoutActionBar = Loadable(lazy(() => import('../views/forms/layouts/ActionBar')));
// const FrmLayoutStickyActionBar = Loadable(lazy(() => import('../views/forms/layouts/StickyActionBar')));

// forms plugins routing
// const FrmAutocomplete = Loadable(lazy(() => import('../views/forms/plugins/AutoComplete')));
// const FrmMask = Loadable(lazy(() => import('../views/forms/plugins/Mask')));
// const FrmClipboard = Loadable(lazy(() => import('../views/forms/plugins/Clipboard')));
// const FrmRecaptcha = Loadable(lazy(() => import('../views/forms/plugins/Recaptcha')));
// const FrmWysiwugEditor = Loadable(lazy(() => import('../views/forms/plugins/WysiwugEditor')));
// const FrmModal = Loadable(lazy(() => import('../views/forms/plugins/Modal')));
// const FrmTooltip = Loadable(lazy(() => import('../views/forms/plugins/Tooltip')));

// table routing
// const TableBasic = Loadable(lazy(() => import('../views/forms/tables/TableBasic')));
// const TableDense = Loadable(lazy(() => import('../views/forms/tables/TableDense')));
// const TableEnhanced = Loadable(lazy(() => import('../views/forms/tables/TableEnhanced')));
// const TableData = Loadable(lazy(() => import('../views/forms/tables/TableData')));
// const TableCustomized = Loadable(lazy(() => import('../views/forms/tables/TablesCustomized')));
// const TableStickyHead = Loadable(lazy(() => import('../views/forms/tables/TableStickyHead')));
// const TableCollapsible = Loadable(lazy(() => import('../views/forms/tables/TableCollapsible')));

// forms validation
// const FrmFormsValidation = Loadable(lazy(() => import('../views/forms/forms-validation')));
// const FrmFormsWizard = Loadable(lazy(() => import('../views/forms/forms-wizard')));

// chart routing
// const ChartApexchart = Loadable(lazy(() => import('../views/forms/chart/Apexchart')));
// const OrgChartPage = Loadable(lazy(() => import('../views/forms/chart/OrgChart')));

// basic ui-elements routing
// const BasicUIAccordion = Loadable(lazy(() => import('../views/ui-elements/basic/UIAccordion')));
// const BasicUIAvatar = Loadable(lazy(() => import('../views/ui-elements/basic/UIAvatar')));
// const BasicUIBadges = Loadable(lazy(() => import('../views/ui-elements/basic/UIBadges')));
// const BasicUIBreadcrumb = Loadable(lazy(() => import('../views/ui-elements/basic/UIBreadcrumb')));
// const BasicUICards = Loadable(lazy(() => import('../views/ui-elements/basic/UICards')));
// const BasicUIChip = Loadable(lazy(() => import('../views/ui-elements/basic/UIChip')));
// const BasicUIList = Loadable(lazy(() => import('../views/ui-elements/basic/UIList')));
// const BasicUITabs = Loadable(lazy(() => import('../views/ui-elements/basic/UITabs')));

// advance ui-elements routing
// const AdvanceUIAlert = Loadable(lazy(() => import('../views/ui-elements/advance/UIAlert')));
// const AdvanceUIDialog = Loadable(lazy(() => import('../views/ui-elements/advance/UIDialog')));
// const AdvanceUIPagination = Loadable(lazy(() => import('../views/ui-elements/advance/UIPagination')));
// const AdvanceUIProgress = Loadable(lazy(() => import('../views/ui-elements/advance/UIProgress')));
// const AdvanceUIRating = Loadable(lazy(() => import('../views/ui-elements/advance/UIRating')));
// const AdvanceUISnackbar = Loadable(lazy(() => import('../views/ui-elements/advance/UISnackbar')));
// const AdvanceUISkeleton = Loadable(lazy(() => import('../views/ui-elements/advance/UISkeleton')));
// const AdvanceUISpeeddial = Loadable(lazy(() => import('../views/ui-elements/advance/UISpeeddial')));
// const AdvanceUITimeline = Loadable(lazy(() => import('../views/ui-elements/advance/UITimeline')));
// const AdvanceUIToggleButton = Loadable(lazy(() => import('../views/ui-elements/advance/UIToggleButton')));
// const AdvanceUITreeview = Loadable(lazy(() => import('../views/ui-elements/advance/UITreeview')));

// pricing page routing
// const PagesPrice1 = Loadable(lazy(() => import('../views/pages/pricing/Price1')));
// const PagesPrice2 = Loadable(lazy(() => import('../views/pages/pricing/Price2')));

// utilities routing
// const UtilsTypography = Loadable(lazy(() => import('../views/utilities/Typography')));
// const UtilsColor = Loadable(lazy(() => import('../views/utilities/Color')));
// const UtilsShadow = Loadable(lazy(() => import('../views/utilities/Shadow')));
// const UtilsMaterialIcons = Loadable(lazy(() => import('../views/utilities/MaterialIcons')));
// const UtilsTablerIcons = Loadable(lazy(() => import('../views/utilities/TablerIcons')));
// const UtilsAnimation = Loadable(lazy(() => import('../views/utilities/Animation')));
// const UtilsGrid = Loadable(lazy(() => import('../views/utilities/Grid')));

// sample page routing
const SamplePage = Loadable(lazy(() => import('../views/sample-page')));
const ObjectListView = Loadable(lazy(() => import('../components/StandardPages/List')));
const ObjectNewRecordView = Loadable(lazy(() => import('../components/StandardPages/New')));
const ObjectDetailView = Loadable(lazy(() => import('../components/StandardPages/Detail')));
const ObjectDetailEdit = Loadable(lazy(()=> import('../components/StandardPages/Edit')))

const Payment = Loadable(lazy(() => import("../components/Settings/Plans")));
const ActivePlan = Loadable(lazy(() => import("../components/Settings/ActivePlan")));
// const Regex = Loadable(lazy(() => import("../components/StandardPages/Regex")));
/**Settings Page routing */
const ViewTable = Loadable(lazy(() => import('../components/SchemaBuilder/ViewTable')))
const RecurringPayment = Loadable(lazy(() => import('../components/Settings/RecurringPayment')));


// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/sample-page',
            element: <SamplePage />
        },
        {
            path: '/dashboard/default',
            element: <DashboardDefault />
        },
        {
            path: '/dashboard/csn',
            element: <Dashboard />
        },
        {
            path: '/dynamic-table/:id/list',
            element: <ObjectListView />
        },
        {
            path: '/settings/:setup_type/',
            element: <Settings />
        },
        {
            path: '/settings/:setup_type/:operation_type',
            element: <Settings />
        },
        {
            path: '/settings/:setup_type/:operation_type/:id',
            element: <Settings />
        },
        {
          path: '/plans',
          element: <ActivePlan />
        },
        {
          path: '/recurring',
          element: <RecurringPayment />
        },
        {
            path: '/settings/:setup_type/:operation_type/:id/:tableid/:field-permission',
            element: <Settings />
        },
        {

            path: '/dynamic-table/:id/add',
            element: <ObjectNewRecordView />
        },
        {
            path: '/dynamic-table/:id/edit/:dynamicDataId',
            element: <ObjectDetailEdit />
        },
        {
            path: '/dynamic-table/:dynamicTableId/details/:dynamicDataId',
            element: <ObjectDetailView />
        },
        {
            path: '/followups',
            element: <FollowupLayout />
        },
        {
            path: '/followups/:type/:id',
            element: <FollowupLayout />
        },
        {
            path: '/pivotreport',
            element: <ListPivot />
        },
        {
            path: '/pivotreport/add-new',
            element: <NewReport />
        },
        {
            path: '/pivotreport/add',
            element: <NewPivotReports />
        },
        {
            path: '/pivotreport/edit/:reportId',
            element: <EditPivotReports />
        },
        {
            path: '/pivotreport/edit-new/:reportId',
            element: <EditReport />
        },
        {
            path: '/pivotreport/view/:reportId',
            element: <PivotView />
        },
        {
            path: '/pivotreport/msis-29-report-new',
            element: <PivotPatientViewNew />
        },
        {
            path: '/pivotreport/msis-29-report-old',
            element: <PivotPatientViewOld />
        },
        {
            path: '/pivotreport/back-adverse',
            element: <BackAdverseView />
        },
        {
            path: '/pivotreport/adverse-events-report',
            element: <AdverseEventReport />
        },
        {
            path: '/pivotreport/tableview/:reportId',
            element: <PivotView />
        },
        {
            path: '/table/:id/details',
            element: <ViewTable />
        },
        //
        {
            path:"/patient-questionnaire/:id",
            element:<PatientQuestionnaireView />,

        },
        {
            path:"/patient-questionnaire/:id/userid/:userid/:time_period",
            element:<PatientQuestionnaireView />,

        },
        {
            path:"/patient-questionnaire/:id/:send_questionnaire_id/:time_period",
            element:<PatientQuestionnaireView />,

        },
        {
            path:"/table/:id/dynamic-fields/add",
            element:<AddField />,
        },{
            path:"/campaign/:questionnaire_id",
            
            element:<PublicCampaign/>
        },


//                         
//                         <Route
//                           path="/dynamic-table/:id/list/:pageNumber"
//                           exact
//                           component={ObjectListView}
//                         />                        

//                         <Route
//                           path="/dynamic-table/:id/clone/:dynamicDataId"
//                           exact
//                           component={ObjectCloneView}
//                         />
//                         <Route
//                           path="/patientFollowups/:type_id/details/:res_id"
//                           exact
//                           component={FollowUps}
//                         />

//                         <Route path="/table/:id/details" exact component={ViewTable} />
//                         <Route path="/table/edit/:id" exact component={EditTable} />

//                         <Route
//                           path="/table/:id/dynamic-fields"
//                           exact
//                           component={ListField}
//                         />
//                         <Route
//                           path="/table/:id/dynamic-fields/add"
//                           exact
//                           component={AddField}
//                         />
//                         <Route
//                           path="/table/:id/dynamic-fields/edit/:field_id"
//                           exact
//                           component={EditField}
//                         />

//                         <Route
//                           path="/table/:id/dynamic-list/add"
//                           exact
//                           component={AddList}
//                         />
    ],
    
};

export default MainRoutes;
