
/*
****************************************************************
Name			:	DateTime
Author 		    :	Deependra Singh 
Date			:	31/12/2021
Description		:	Utility for managing date with timezones
****************************************************************

MODIFICATION LOG ::
*********************
* Version 		Developer 		    Date 			Description
*-----------------------------------------------------------------------------------------------
* 1.0 			Deependra Singh 	31/12/2021 		Initial Creation 
*/

import momentTimezone from 'moment-timezone';
import moment from "moment";
import {DateTime} from 'luxon';
import InfoIcon from '@mui/icons-material/Info'
import { Tooltip } from '@mui/material';

class DateUtil {
   /**
    * @desc : Returns Object of UTC date and timezone
    * @param {{date: Date, timezone: string}|string} date : Input JS Date Object or ISO date string
    * @param {boolean} isStatic : true: display time with given Timezone and false: timezone of user
    * @returns {Date} : like {date: ISO formated UTC Date string, timezone: IST}
    */
   static getUtcWithTimezone = (date, isStatic = true,timezone = null) => {
       //convert string date into JS Date object
       console.log("this.state.formData[name] date--->",typeof date)
       console.log("this.state.formData[name] date--->", DateTime.fromJSDate(new Date(date)).toUTC().toISO())
       if (typeof date === 'string') {
           //abr->label
           //localeString
            date= new Date(date)
           let dateObj = new Date(date)
        //   date = new Date(date.toLocaleString('en-US', {timeZone: timezone}));



        //    console.log("+++++", date)
        //    console.log("+++++", date.getTimezoneOffset)
           let utc = DateTime.fromJSDate(date).toUTC().toISO(); 
           const timeZoneLabel = momentTimezone.tz.names().find( z => {
               let zone = momentTimezone.tz(z);
               if(zone.zoneAbbr() === timezone){
                   return z;
                }
            });
            
            // console.log(timeZoneLabel,"------",timezone,"--- -->", utc)
        
            let originalDate = new DateTime.fromISO(utc, {zone: timeZoneLabel});


       }


       //Get Local Timezone
       const localTimeZone =timezone==null ?  momentTimezone.tz.guess() : timezone;
    //    console.log("localTimeZone-->",localTimeZone)
       //Prepare TimeZOne Object to perform operations on
       const timezoneObj = momentTimezone.tz(localTimeZone);

       //const abbri = DateTime.local().zone.offsetName(DateTime.now().toMillis(),{format : 'short',locale :'en-IN'})
       //convert input date to UTC date 
    //    let utc = DateTime.fromJSDate(date).toUTC().toISO();
       let utc =  DateTime.fromJSDate(new Date(date)).toUTC().toISO()
       console.log("this.state.formData[name]  again-->", utc)
       return {
           date: utc,
           timezone: timezoneObj.zoneAbbr(),
           isStatic: isStatic,
           localTimeZone: localTimeZone,
       };
   }
   /**
    * @Desc :  Formats date with timezone appended 
    * @todo : utalize isStatic flag recived with date to pick timezone statically from input or from user preference
    * @param {{date: Date, timezone: string}} dateObject :  e.g. {date: Date("12-30-2001 06:31"), timezone: "IST"}
    * @param {string} format : Date format like M-d-y
    * @returns {string} : 12-30-2001 02:28 PM (IST)
    */
   static formatDate = (dateObject,isStatic=true, format = "MM/dd/y") => {
    //    console.log("dateObject---------------------------------------------------------",dateObject, "====", isStatic)
    try {
        if(dateObject === null)  return "";

       //In case we receive Date Object as json string, then convert into object
        if (typeof dateObject === 'string') {
           try {
               dateObject = JSON.parse(dateObject);
           } catch(err){
               //If input string is not json then should be a plain date string without timezone like "2021-12-10T00:00:00.000+05:30"
               return this.formatOriginalDate(dateObject);
           };
           
        }
       //convert timezone abbrivation to timezone label like IST to Asia/Calcuta
       const timeZoneLabel = momentTimezone.tz.names().find( z => {
           let zone = momentTimezone.tz(z);
           if(zone.zoneAbbr() === dateObject.timezone){
               return z;
           }
       });



       let originalDate =''
       if(dateObject.isStatic == false){

        const currentTimeZoneLabel = momentTimezone.tz.guess()
        let falseZoneAbbreviation =momentTimezone.tz(currentTimeZoneLabel).zoneAbbr()
        
            originalDate = new DateTime.fromISO(dateObject.date, {zone: currentTimeZoneLabel});

            return (
                <>
                    <span>{`${originalDate.toFormat(format)}`}</span>
                    <Tooltip  title={falseZoneAbbreviation}>
                        <InfoIcon fontSize="1rem"/>
                    </Tooltip>
                </>
            );


       }else{
        originalDate = new DateTime.fromISO(dateObject.date, {zone: timeZoneLabel});
        //   return `${originalDate.toFormat(format)} (${dateObject.timezone})`;
            return (
                <>
                    <span>{`${originalDate.toFormat(format)}`}</span>
                    <Tooltip  title={dateObject.timezone}>
                        <InfoIcon fontSize="1rem"/>
                    </Tooltip>
                </>
            );
       }

    //    console.log(timeZoneLabel,"---dateObject-->", dateObject)



       //convert UTC time to original time based on timezone received
    //    const originalDate = new DateTime.fromISO(dateObject.date, {zone: timeZoneLabel});
    // //    return `${originalDate.toFormat(format)} (${dateObject.timezone})`;
    //    return <><span>{`${originalDate.toFormat(format)}`}</span><i className="info-icon mdi mdi-information" data-toggle="tooltip" data-placement="top" aria-hidden="true" title={`${dateObject.timezone}`}  ></i></>;
    } catch(e){
        console.log("Failed to formatDate", e);
        return null;
    }
   }
   /**
    * @Desc :  Appends Timezone abbrivation in input date. There will be no change in date.
    * @param {{date: Date, timezone: string}} date :  e.g. {date: Date("12-30-2001"), timezone: "IST"}
    * @param {string} format : Date format like M-d-y
    * @returns {ReactElement|string} : React Element with string like 12-30-2001 (IST)
    */
    static formatOriginalDate = (date, format = "MM/dd/y") => {
        /**@type {number} @desc ofset in minutes from GMT e.g. 330 for IST*/
        let dateOffset = DateUtil.extractOffset(date);
        if(isNaN(dateOffset)){
            dateOffset = 0;
        }
       /**
        * @type {{any: string}} e.g. {IST: "Asia/Kolkata"}
        */
       const timeZone = DateUtil.getTimezoneFromOffset(dateOffset);

       console.log("timeZone-->",timeZone)
       
       //so we got the abbrivattion lets use datetime now from from ISO
       //convert UTC time to original time based on timezone received
       //Just in case we dont receive anything from timezone. this date will get printed with default system timezone
       let formatedDate = new DateTime.fromISO(date);
       const [firstAbbri, firstZoneName] = Object.entries(timeZone)[0];
       if(Object.keys(timeZone).length > 0) {
           //formatedDate = new DateTime.fromISO(date, {zone: firstZoneName}).toFormat(format)+ ` (${firstAbbri})`;
           formatedDate = new DateTime.fromISO(date, {zone: firstZoneName}).toFormat(format);
           formatedDate = (<>
            <span>{`${formatedDate}`}</span>
                <Tooltip  title={firstAbbri}>
                    <InfoIcon fontSize="1rem" />
                </Tooltip>
            </>);
           return formatedDate;
           // In case of multiple timezons. Display them on hover.
        //    if(Object.keys(timeZone).length > 1) {
        //        //Lets delete 1st item from timezone as it is already used in our response string
        //        delete timeZone[firstAbbri]
        //     //    formatedDate = <><span>{`${formatedDate}`}</span><i className="info-icon mdi mdi-information" data-toggle="tooltip" data-placement="top" aria-hidden="true" title={`${Object.keys(timeZone)}`}  data-placement="top"></i></>;
        //    }
       }
       return formatedDate;
   }
   /**
    * @Desc :  Appends Timezone abbrivation in input date. There will be no change in date.
    * @param {{date: Date, timezone: string}} date :  e.g. {date: Date("12-30-2001"), timezone: "IST"}
    * @param {string} format : Date format like M-d-y
    * @returns {ReactElement|string} : React Element with string like 12-30-2001 (IST)
    */
    static formatOriginalDateWithIcon = (date, format = "MM/dd/y") => {
        //isNaN(DateUtil.extractOffset(date) will return true if Date is not in ISO format. 
        if(!date || isNaN(DateUtil.extractOffset(date)))
        {
            return null
        }
       /**
        * @type [string]
        */
       const timeZone = DateUtil.getTimezoneFromOffset(DateUtil.extractOffset(date));

    //    console.log("timeZone-->",timeZone)
       
       //so we got the abbrivattion lets use datetime now from from ISO
       //convert UTC time to original time based on timezone received
       //Just in case we dont receive anything from timezone. this date will get printed with default system timezone
       let formatedDate = new DateTime.fromISO(date);
       const [firstAbbri, firstZoneName] = Object.entries(timeZone)[0];
       if(Object.keys(timeZone).length > 0) {
           formatedDate = new DateTime.fromISO(date, {zone: firstZoneName}).toFormat(format);
        //    formatedDate = new DateTime.fromISO(date, {zone: firstZoneName}).toFormat(format)+ ` (${firstAbbri})`;
           // In case of multiple timezons. Display them on hover.
           if(Object.keys(timeZone).length > 1) {
               //Lets delete 1st item from timezone as it is already used in our response string
               delete timeZone[firstAbbri]
               formatedDate = (
                <>
                    <span>{`${formatedDate}`}</span>
                    <Tooltip  title={firstAbbri}>
                        <InfoIcon fontSize="1rem" />
                    </Tooltip>
                </>
            );
           }
       }
       return formatedDate;
   }
    /**
    * @Desc :  Appends Timezone abbrivation in input date. There will be no change in date without info icon.
    * @param {{date: Date, timezone: string}} date :  e.g. {date: Date("12-30-2001"), timezone: "IST"}
    * @param {string} format : Date format like M-d-y
    * @returns {ReactElement|string} : React Element with string like 12-30-2001 (IST)
    */
    static formatOriginalDateWithOutIcon = (date, format = "MM/dd/y") => {
       
        if(!date || isNaN(DateUtil.extractOffset(date)))
        {
            return null
        }
       /**
        * @type [string]
        */
       const timeZone = DateUtil.getTimezoneFromOffset(DateUtil.extractOffset(date));

    //    console.log("timeZone-->",timeZone)
       
       //so we got the abbrivattion lets use datetime now from from ISO
       //convert UTC time to original time based on timezone received
       //Just in case we dont receive anything from timezone. this date will get printed with default system timezone
       let formatedDate = new DateTime.fromISO(date).toFormat(format);
       const [firstAbbri, firstZoneName] = Object.entries(timeZone)[0];
       if(Object.keys(timeZone).length > 0) {
           formatedDate = new DateTime.fromISO(date, {zone: firstZoneName}).toFormat(format);
        
       }
       return formatedDate;
   }
   
   /**
    * @desc : Returns Timezone from offset in minutes
    * @param {number} offset : in minutes
    * @returns {[{abbri: string}]} : Abbrivation like [{"IST": "Asia/Calcutta"],[{"SLST" :"Asia/Colombo"}]
    */
   static getTimezoneFromOffset = (offset) => {
       //Iterating TZ list to get required one
       return  momentTimezone.tz.names().reduce( (abbrArray = {}, z) => {
           let zone = momentTimezone.tz(z);
           if(zone.utcOffset() === offset){
               const _abbri = zone.zoneAbbr();
               //isNaN to skip numeric records like +530/-08
               if(_abbri && isNaN(_abbri)) abbrArray[_abbri]= z;
           }
           return abbrArray;
       }, []);
       /**@todo why not use map objects for this type of response */
   }
   /**
    * @desc : Returns offset in minutes from input date
    * @param {string} date : iso date string like 2021-12-10T00:00:00.000+05:30
    * @returns {number} : offsets in minutes like +330 for UTC+5:30
    */
   static extractOffset(date) {
       if(date.substring(date.length-1)==="Z"){
           return 0;
       }
       const offsetParts = date.substring(date.length - 5).split(":"); // like 05:30 to [05,30]
       //Take + or - before offset to determine its nature, along with time converted into minutes
       return parseInt( date.charAt(date.length - 6) + '' + (parseInt(offsetParts[0])*60 + parseInt(offsetParts[1])));
   }
   /**
    * @desc :Returns Time Zone abbrivation like IST
    * @returns {string}
    */
   static getLocalTimeZoneAbbr = () => {
       const localTimeZone =  momentTimezone.tz.guess();
       const timezoneObj = momentTimezone.tz(localTimeZone);
       return timezoneObj.zoneAbbr();
   }
   /**
    * @desc :Return Object of local timezone with label, abbrivation and offset 
    * @returns {Object} 
    */
   static getLocalTimeZoneInfo = () => {
       const localTimeZone =  momentTimezone.tz.guess();
       const timezoneObj = momentTimezone.tz(localTimeZone);
       return {
           label: localTimeZone,
           abbr: timezoneObj.zoneAbbr(),
           offset: timezoneObj.utcOffset()
       };
   }
   /**
    * @desc :Returns Object of input date and timezone
    * @param {Date} date : Date Object
    * @returns {Object}
    */
   static setLocalTimeZoneInfo = (date) => {
       if (date instanceof Date) {
           const localTimeZone =  momentTimezone.tz.guess();
           const timezoneObj = momentTimezone.tz(localTimeZone);

           return {
               date: date,
               timezone: timezoneObj.zoneAbbr()
           };
       }
   }
   /**
    * @desc :getDateValue reat Object of input date and timezone
    * @param {Date} date : Date Object
    * @returns {String}
    */


    static getDateValue = (d) => {
        // console.log("Test from lib", d)
        // console.log("Test from lib type",typeof d)
        
        // let dt =''
        // let isoString =''

        // if(typeof d =='object'){
            
        // }
        // else{

        // }

        if(!(d)) return null;//if null or undefined return null
        let dt = typeof d =='object' && d.date ? new Date(d.date) : (typeof d =='object' ? d : new Date(d))

        if(typeof d == 'object' && !d.date){
            console.log("Hei , i m in")
            return null
        }
        if(dt != 'Invalid Date'){ // console.log(dt)
            // console.log("DateUtil: Not a invalid date");
            // let dt = new Date(d.date) 
            let isoString = dt.toISOString()


            // console.log(dt.toISOString(), " Form DAteUtill ninin -->")
            // console.log( " Form DAteUtill ninin -265->",moment(isoString).format('YYYY-MM-DD'), " Form DAteUtill -->")
            // console.log( " Form DAteUtill ninin 266-->", moment(d.date).format('YYYY-MM-DD'))

            // return  moment(d.date).format('YYYY-MM-DD')
            return  moment(isoString).format('YYYY-MM-DD')
        } else 
            return null;
    }
    /**
    * @desc :it is upgradation of getDateValue method without use of moment
    * getDateValue reat Object of input date and timezone
    * @param {jsDate} date : Date Object i.e. {date:Date, timezon:string, isStatic}
    * @returns {String}
    */


     static getDateValueFromJsDate = (jsDate, format = "MM/dd/y") => {
        return new DateTime.fromJSDate(jsDate).toFormat(format);
    }

}
export default DateUtil;