import { useEffect, useRef, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useSelector } from "react-redux";

// material-ui
import { useTheme } from "@mui/material/styles";
import axios from "axios"
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  ClickAwayListener,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Divider,
  FormGroup,
  Grid,
  InputAdornment,
  InputLabel,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  OutlinedInput,
  Paper,
  Popper,
  Stack,
  Switch,
  Typography,
  TextField,
} from "@mui/material";

// third-party
import PerfectScrollbar from "react-perfect-scrollbar";

// project imports
import MainCard from "../../../../ui-component/cards/MainCard";
import Transitions from "../../../../ui-component/extended/Transitions";
import UpgradePlanCard from "./UpgradePlanCard";
import useAuth from "../../../../hooks/useAuth";
import User1 from "../../../../assets/images/users/user-round.svg";

import { gridSpacing } from "../../../../store/constant";
// assets
import { IconLogout, IconSearch, IconSettings, IconUser} from "@tabler/icons";
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import useConfig from "../../../../hooks/useConfig";

import { styled } from "@mui/material/styles";

// project imports
import AnimateButton from "../../../../ui-component/extended/AnimateButton";

// ==============================|| PROFILE MENU ||============================== //

const CardStyle = styled(Card)(({ theme }) => ({
  background: theme.palette.mode === "dark" ? theme.palette.dark[800] : theme.palette.warning.light,
  marginTop: "16px",
  marginBottom: "16px",
  overflow: "hidden",
  position: "relative",
  "&:after": {
    content: '""',
    position: "absolute",
    width: "200px",
    height: "200px",
    border: "19px solid ",
    borderColor: theme.palette.mode === "dark" ? theme.palette.warning.main : theme.palette.warning.main,
    borderRadius: "50%",
    top: "65px",
    right: "-150px",
  },
  "&:before": {
    content: '""',
    position: "absolute",
    width: "200px",
    height: "200px",
    border: "3px solid ",
    borderColor: theme.palette.mode === "dark" ? theme.palette.warning.main : theme.palette.warning.main,
    borderRadius: "50%",
    top: "145px",
    right: "-70px",
  },
}));
const ProfileSection = () => {
  const authState = useSelector((state) => state.auth);
  const theme = useTheme();
  const { borderRadius } = useConfig();
  const navigate = useNavigate();

  const [sdm, setSdm] = useState(true);
  const [value, setValue] = useState("");
  const [notification, setNotification] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const { logout, user } = useAuth();
  const [open, setOpen] = useState(false);
  const [show, setShow] = useState(false);
  const [treatmentData, setTreatmentData] = useState({
    treatmentname: "",
    treatmentaddress: "",
    phonenumber: "",
    logo: "",
    email: user ? user.email : '',
    treatmentemail: "",
    managername: "",
    city: "",
    state: "",
    zipcode: "",
  });
  /**
   * anchorRef is used on different components and specifying one type leads to other components throwing an error
   * */
  const anchorRef = useRef(null);
  const handleLogout = async () => {
    try {
      await logout();
    } catch (err) {
      console.error(err);
    }
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };
  const handleListItemClick = (event, index, route = "") => {
    setSelectedIndex(index);
    handleClose(event);

    if (route && route !== "") {
      navigate(route);
    }
  };
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const handleShow = () => {
    setShow(true);
  };

  const onChange = (event) => {
    setTreatmentData({ ...treatmentData, [event.target.name]: event.target.value });

  };



  const onFileChange = (event) => {
    setTreatmentData({ ...treatmentData, [event.target.name]:  event.target.files[0] });
    // this.state.selectedFile = event.target.files[0];
  };
  
  const onModalSubmit = (e) => {
    e.preventDefault();
    // const formData = new FormData();





    // setTreatmentData({ ...treatmentData, 'email': user.email });
    
    
    console.log(treatmentData)
    // formData.append("treatmentname", this.state.name);
    // formData.append("treatmentaddress", this.state.address);
    // formData.append("phonenumber", this.state.number);
    // formData.append("logo", this.state.selectedFile);
    // formData.append("email", this.props.auth.user.email);
    // formData.append("treatmentemail", this.state.treatmentemail);
    // formData.append("managername", this.state.managername);
    // formData.append("city", this.state.city);
    // formData.append("state", this.state.state);
    // formData.append("zipcode", this.state.zipcode);

    axios
      .post("/api/usersreset/treatmentinfo", treatmentData)
      .then((res) => {
        // Set token to localStorage
        setShow(false);
      })
      .catch((err) => {
      });
  };

  return (
    <>
      <Chip
        sx={{
          height: "48px",
          alignItems: "center",
          borderRadius: "27px",
          transition: "all .2s ease-in-out",
          borderColor: theme.palette.mode === "dark" ? theme.palette.dark.main : theme.palette.primary.light,
          backgroundColor: theme.palette.mode === "dark" ? theme.palette.dark.main : theme.palette.primary.light,
          '&[aria-controls="menu-list-grow"], &:hover': {
            borderColor: theme.palette.primary.main,
            background: `${theme.palette.primary.main}!important`,
            color: theme.palette.primary.light,
            "& svg": {
              stroke: theme.palette.primary.light,
            },
          },
          "& .MuiChip-label": {
            lineHeight: 0,
          },
        }}
        icon={
          <Avatar
            src={User1}
            sx={{
              ...theme.typography.mediumAvatar,
              margin: "8px 0 8px 8px !important",
              cursor: "pointer",
            }}
            ref={anchorRef}
            aria-controls={open ? "menu-list-grow" : undefined}
            aria-haspopup="true"
            color="inherit"
          />
        }
        label={<IconSettings stroke={1.5} size="24px" color={theme.palette.primary.main} />}
        variant="outlined"
        ref={anchorRef}
        aria-controls={open ? "menu-list-grow" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        color="primary"
      />

      <Popper
        placement="bottom"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, 14],
              },
            },
          ],
        }}
      >
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={handleClose}>
            <Transitions in={open} {...TransitionProps}>
              <Paper>
                {open && (
                  <MainCard border={false} elevation={16} content={false} boxShadow shadow={theme.shadows[16]}>
                    <Box sx={{ p: 2, pb: 0 }}>
                      {/* <Stack>
                                                <Stack direction="row" spacing={0.5} alignItems="center">
                                                    <Typography variant="h4">Good Morning,</Typography>
                                                    <Typography component="span" variant="h4" sx={{ fontWeight: 400 }}>
                                                        {user?.name}
                                                    </Typography>
                                                </Stack>
                                                <Typography variant="subtitle2">
                                                {user.profileid ==
                                                "60182ba3abce9a67179ee6ed"
                                                    ? "Physician"
                                                    : user.profileid ==
                                                    "60182b94abce9a67179ee6eb"
                                                    ? "Clinic"
                                                    : user.profileid ==
                                                    "60182b8dabce9a67179ee6e9"
                                                    ? "Investigational Group"
                                                    : "Admin"
                                                }


                                                </Typography>
                                            </Stack> */}
                    </Box>
                    {/* <PerfectScrollbar style={{ height: "100%", maxHeight: "calc(100vh - 250px)", overflowX: "hidden" }}> */}
                    <>
                      <Box sx={{ p: 2, pt: 0 }}>
                        <CardStyle>
                          <CardContent>
                            <Grid container direction="column" spacing={2}>
                              <Grid item>
                                <Typography variant="h4">{user?.name}</Typography>
                              </Grid>
                              <Grid item>
                                <Typography
                                  variant="subtitle2"
                                  color={theme.palette.mode === "dark" ? "textSecondary" : "grey.900"}
                                  sx={{ opacity: theme.palette.mode === "dark" ? 1 : 0.6 }}
                                >
                                  { user && (user.profileid == "60182ba3abce9a67179ee6ed"
                                    ? "Physician"
                                    : user.profileid == "60182b94abce9a67179ee6eb"
                                    ? "Clinic"
                                    : user.profileid == "60182b8dabce9a67179ee6e9"
                                    ? "Investigational Group"
                                    : "Admin")}
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Stack direction="row">
                                  <AnimateButton>
                                    {/* <Button variant="contained" color="warning" sx={{ boxShadow: 'none' }}>
                                                                            Go Premium
                                                                        </Button> */}
                                  </AnimateButton>
                                </Stack>
                              </Grid>
                            </Grid>
                          </CardContent>
                        </CardStyle>

                        <Divider />
                        <List
                          component="nav"
                          sx={{
                            width: "100%",
                            maxWidth: 350,
                            minWidth: 300,
                            zIndex: 1400,
                            backgroundColor: theme.palette.background.paper,
                            borderRadius: "10px",
                            [theme.breakpoints.down("md")]: {
                              minWidth: "100%",
                            },
                            "& .MuiListItemButton-root": {
                              mt: 0.5,
                            },
                          }}
                        >
                          {
                          /**@todo Tempararily removing Active PLan page for physician, As it is not working well for Legacy user physician*/
                          }
                          {user && user.profileid == "60182b94abce9a67179ee6eb" && (
                            <ListItemButton sx={{ borderRadius: `${borderRadius}px` }} selected={selectedIndex === 0} component={Link} to="/plans">
                              <ListItemIcon>
                                <CardMembershipIcon stroke={1.5} size="20px" />
                              </ListItemIcon>
                              <ListItemText
                                primary={
                                  <Typography variant="body2">
                                    Active Plan
                                  </Typography>
                                }
                              />
                            </ListItemButton>
                          )}
                           {user && user.profileid == "60182b81abce9a67179ee6e7" && (<ListItemButton sx={{ borderRadius: `${borderRadius}px` }} selected={selectedIndex === 0} component={Link} to="/settings/setup">
                            <ListItemIcon>
                              <IconSettings stroke={1.5} size="20px" />
                            </ListItemIcon>
                            <ListItemText primary={<Typography variant="body2">Settings</Typography>} />
                          </ListItemButton>

                        )}
                          <ListItemButton sx={{ borderRadius: `${borderRadius}px` }} selected={selectedIndex === 1} onClick={handleShow}>
                            <ListItemIcon>
                              <IconUser stroke={1.5} size="20px" />
                            </ListItemIcon>
                            <ListItemText
                              primary={
                                <Grid container spacing={1} justifyContent="space-between">
                                  <Grid item>
                                    <Typography variant="body2">Update Treatment Center</Typography>
                                  </Grid>
                                  <Grid item></Grid>
                                </Grid>
                              }
                            />
                          </ListItemButton>
                          <ListItemButton sx={{ borderRadius: `${borderRadius}px` }} selected={selectedIndex === 4} onClick={handleLogout}>
                            <ListItemIcon>
                              <IconLogout stroke={1.5} size="20px" />
                            </ListItemIcon>
                            <ListItemText primary={<Typography variant="body2">Logout</Typography>} />
                          </ListItemButton>
                        </List>
                      </Box>
                    </>
                  </MainCard>
                )}
              </Paper>
            </Transitions>
          </ClickAwayListener>
        )}
      </Popper>

      <Dialog
        open={show}
        //   onClose={this.handleClose}
        sx={{
          // position: "absolute",
          // width: { xs: 280, lg: 450 },
          // width: "80%",
          // top: "40%",
          // left: "50%",
          // transform: "translate(-50%, -50%)",
          width: "100%",
        }}
        maxWidth={"lg"}
        scroll={"body"}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">
          <h5 className="modal-title text-center" id="exampleModalLabel2">
            Update Treatment Center
          </h5>
        </DialogTitle>
        <form noValidate onSubmit={onModalSubmit} encType="multipart/form-data">
          <DialogContent>
            <>
              <Grid container>
                <Grid item xs={12}>
                  <FormGroup>
                    <InputLabel>Treatment Center Name</InputLabel>
                    <TextField onChange={onChange} type="text" id="name" name="treatmentname" format={process.env.REACT_APP_DATE_FORMAT} placeholder="Treatment Center Name" />
                  </FormGroup>
                </Grid>
                <Grid item xs={12}>
                  <FormGroup>
                    <InputLabel>Preferred Email Address </InputLabel>
                    <TextField
                      onChange={onChange}
                      type="text"
                      id="treatmentemail"
                      name="treatmentemail"
                      format={process.env.REACT_APP_DATE_FORMAT}
                      placeholder="Preferred Email Address"
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={12}>
                  <FormGroup>
                    <InputLabel>Database Manager Name </InputLabel>
                    <TextField
                      onChange={onChange}
                      type="text"
                      id="managername"
                      name="managername"
                      format={process.env.REACT_APP_DATE_FORMAT}
                      placeholder="Database Manager Name "
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={12}></Grid>
                <Grid item xs={12}>
                  <FormGroup>
                    <InputLabel>Treatment Center Address</InputLabel>
                    <FormGroup>
                      <TextField onChange={onChange} type="text" id="address" name="treatmentaddress" format={process.env.REACT_APP_DATE_FORMAT} placeholder="Treatment Center Address" />
                    </FormGroup>
                  </FormGroup>
                </Grid>
                <Grid item xs={12}>
                  <FormGroup>
                    <InputLabel>City</InputLabel>
                    <FormGroup>
                      <TextField onChange={onChange} type="text" id="city" name="city" placeholder="Treatment Center City" />
                    </FormGroup>
                  </FormGroup>
                </Grid>
                <Grid item xs={12}></Grid>
                <Grid item xs={12}>
                  <FormGroup>
                    <InputLabel>Zipcode</InputLabel>
                    <FormGroup>
                      <TextField onChange={onChange} type="text" id="zipcode" name="zipcode" placeholder="Zipcode" />
                    </FormGroup>
                  </FormGroup>
                </Grid>
                <Grid item xs={12}>
                  <FormGroup>
                    <InputLabel>State</InputLabel>
                    <FormGroup>
                      <TextField onChange={onChange} type="text" id="state" name="state" format={process.env.REACT_APP_DATE_FORMAT} placeholder="State" />
                    </FormGroup>
                  </FormGroup>
                </Grid>
                <Grid item xs={12}>
                  <FormGroup>
                    <InputLabel>Treatment Center Logo</InputLabel>
                    <FormGroup>
                      <TextField
                        type="file"
                        name="logo"
                        onChange={onFileChange}
                        accept=".png, .jpg, .jpeg"
                        placeholder="Treatment Center Logo"
                      />
                    </FormGroup>
                  </FormGroup>
                </Grid>
                <Grid item xs={12}>
                  <FormGroup>
                    <InputLabel>Treatment Center Phone Number</InputLabel>
                    <FormGroup>
                      <TextField onChange={onChange} type="number" id="number" name="phonenumber" placeholder="Phone Number" />
                    </FormGroup>
                  </FormGroup>
                </Grid>
                {/* <Grid item xs={12}>
                    <FormGroup>
                      <button type="submit" className="btn btn-primary mr-2">
                        Submit
                      </button>
                      <button
                        type="button"
                        //   onClick={this.hidemodal}
                        className="btn btn-primary mr-2"
                      >
                        Cancel
                      </button>
                    </FormGroup>
                    
                  </Grid> */}
              </Grid>
            </>
          </DialogContent>
          <DialogActions>
            <Grid item xs={12}>
              <Grid container spacing={gridSpacing}>
                <Grid item sm zeroMinWidth></Grid>
                <Grid item>
                  <AnimateButton>
                    <Button
                      onClick={() => {
                        setShow(false);
                      }}
                      varient="outlined"
                    >
                      {" "}
                      Cancel{" "}
                    </Button>
                  </AnimateButton>
                </Grid>
                <Grid item>
                  <AnimateButton>
                    <Button type="submit" variant="contained">
                      Save
                    </Button>
                  </AnimateButton>
                </Grid>
              </Grid>
            </Grid>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default ProfileSection;
