/**
 * @module
 * @desc 	This file combines all the reducers into one.
 * @author  Abhijeet Gupta <abhijeet.gupta@appcino.com>
 * @version 1.0
 * @date 01/01/2022
 * <br><br>
 * ****************************************************************

 *  MODIFICATION LOG
 * <table >

 *  <tr><td>Version   </td><td> Developer     </td><td>Date 		   </td><td>Description</td></tr>
 
 *  <tr><td>1.0 	    </td><td>Abhijeet gupta  </td><td>01/01/2022	</td><td>Initial Creation</td></tr>

 *  </table>
*/

//importing reducers
import { combineReducers } from "redux";
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import authReducer from "./authReducer";
import dataReducer from "./dataReducer";
// import chatbotReducer from "./chatbotReducer";
import countryReducer from "./countryReducer";
import languageReducer from "./languageReducer";
import industryReducer from "./industryReducer";
import domainReducer from "./domainReducer";
import errorReducer from "./errorReducer";
import tableReducer from "./tableReducer";
import fieldReducer from "./fieldReducer";
import viewReducer from "./viewReducer";
import reportReducer from "./reportReducer";
import permissionReducer from "./permissionReducer";
import snackbarReducer from "../store/slices/snackbar";
import cartReducer from '../store/slices/cart';
import menuReducer from '../store/slices/menu';


//the combineReducer will call each and every reducer with the state and action EVERY TIME.
export default combineReducers({
  //authReducer is the reducer for the authentication module
  auth: authReducer,
  //dataReducer is the reducer for the data module
  data: dataReducer,
  //chatbotReducer is the reducer for the chatbot module
  // chatbot: chatbotReducer,
  //countryReducer is the reducer for the country module
  country: countryReducer,
  //languageReducer is the reducer for the language module
  language: languageReducer,
  //industryReducer is the reducer for the industry module
  industry: industryReducer,
  //domainReducer is the reducer for the domain module
  domain: domainReducer,
  //errorReducer is the reducer for the error module
  errors: errorReducer,
  //tableReducer is the reducer for the table module
  table:tableReducer,
  //fieldReducer is the reducer for the field module
  field:fieldReducer,
  //viewReducer is the reducer for the view module
  view:viewReducer,
  //reportReducer is the reducer for the report module
  report:reportReducer,
  //permissionReducer is the reducer for the permission module
  permission:permissionReducer,
  snackbar: snackbarReducer,
  cart: persistReducer(
      {
          key: 'cart',
          storage,
          keyPrefix: 'berry-'
      },
      cartReducer
  ),
  menu : menuReducer
});
