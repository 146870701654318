import { lazy } from 'react';
import { useRoutes, Navigate } from 'react-router-dom';


import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure';
// routes
import MainRoutes from './MainRoutes';
import LoginRoutes from './LoginRoutes';
import AuthenticationRoutes from './AuthenticationRoutes';
import Loadable from '../ui-component/Loadable';
import ConfirmPage from '../views/pages/ConfirmPage';
import PublicCampaign from '../components/DialogBoxes/PublicCampaign';
const PatientQuestionnaireView = Loadable(lazy(() => import('../components/Questionnaire/PatientView')))

const PagesLanding = Loadable(lazy(() => import('../views/pages/landing')));
const ClinicSignUp = Loadable(lazy(() => import('../components/Subscription/index')));
// const Recurring = Loadable(lazy(() => import("../components/StandardPages/Recurring")));
const Blog = Loadable(lazy(() => import('../views/pages/Blog')));
const Faqs = Loadable(lazy(() => import('../views/pages/Faqs')));
const Unsubscribe = Loadable(lazy(() => import('../views/pages/Unsubscribe')));




loadStripe.setLoadParameters({ advancedFraudSignals: false });

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {


    const stripePromise = loadStripe(
        process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
      )
    //PagesLanding is responsible for a nice root page for site where we can advertise about product and with a button for dashboard redirection
    // return useRoutes([{ path: '/', element: <PagesLanding /> }, AuthenticationRoutes, LoginRoutes, MainRoutes]);
    //Redirected root url to dashboard
    return useRoutes([

        
        { path: '/clinic-sign-up', element: <Elements stripe={stripePromise}><ClinicSignUp /> </Elements> },
        // { path: '/', element: <Navigate replace to="/dashboard/csn"/> }, 
        { path: '/', element: <PagesLanding /> }, 
        { path: '/blog', element: <Blog /> }, 
        { path: '/faqs', element: <Faqs /> }, 
        { path: '/confirm-page', element: <ConfirmPage /> }, 
        {
            path: '/show-questionnaire/:send_questionnaire_id',
            element: <PatientQuestionnaireView />
        },
        {
            path: '/unsubscribe/:token',
            element: <Unsubscribe />
        },
        {
            path: '/p/:send_questionnaire_id',
            element: <PatientQuestionnaireView />
        },
        
        
        AuthenticationRoutes, 
        LoginRoutes, 
        MainRoutes
    ]);
}
