import { useState } from 'react';
import axios from '../../utils/axios';
// material-ui
import { useTheme } from '@mui/material/styles';
import { Button, CircularProgress, Dialog, Drawer, Fab, Grid, IconButton, InputLabel, Tooltip,DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,Typography } from '@mui/material';
import MailIcon from '@mui/icons-material/Mail';
import {  Stack, Switch, TextField } from '@mui/material';
import { IconSettings } from '@tabler/icons';

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';

// project imports
// import BorderRadius from './BorderRadius';
// import Layout from './Layout';
// import PresetColor from './PresetColor';
// import FontFamily from './FontFamily';
// import InputFilled from './InputFilled';
// import BoxContainer from './BoxContainer';
import AnimateButton from '../../ui-component/extended/AnimateButton';
import { gridSpacing } from '../../store/constant';

import useConfig from '../../hooks/useConfig';
import SubCard from '../../ui-component/cards/SubCard';

// ==============================|| LIVE CUSTOMIZATION ||============================== //
const intialData = {
    name:'',
    title:'',
    description:'',
    img:'',
    notApplicable:true,
}


const Feedback = () => {
    const theme = useTheme();

    const { outlinedFilled, onChangeOutlinedField } = useConfig();
    // drawer on/off
    const [open, setOpen] = useState(false);
    const [showDialog, setShowDialog] = useState(false);

    const [imageBase64, setImageBase64] = useState('');
    const [file, setFile] = useState();
    const [clickSubmitButton, setClickSubmitButton] = useState(false);

    const handleToggle = () => {
        setOpen(!open);
    };

    const handleCloseDialog = (e) => {
        setShowDialog(false)
    }

   
    const [formData, setFormData] = useState(intialData)
    const [showMsg, setShowMsg] = useState('')

    const handleInputChange = (e) => {
        // console.log(e.target.name)
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value,
        });
      };


        const uploadImage = (e) => {
            
            formData['imgData'] = e.target.files[0]
                setImageBase64({imageBase64:  e.target.files[0]})

                var file = e.target.files[0];
                // console.log(file);
                var reader = new FileReader();
                reader.onloadend = function () {
            
                setFormData({
                ...formData,
                [e.target.name]: reader.result
                })
                
        
                }
                
                reader.readAsDataURL(file);
        }

        const handleSubmit = (e) => {
            
            e.preventDefault();
            if(formData['notApplicable'] && formData['img'] ==''){
                // alert("Please Uncheck Attach Image Button, If There is no image file")
                setShowDialog(true)
                return false
            }
            
            setClickSubmitButton(true);

            axios
            .post("/api/users/feedback",formData )
            .then((res) => {
                setClickSubmitButton(false);
                setFormData({...formData,'notApplicable': true})
                setFormData(intialData);
            setShowMsg('Feedback Sent Successfully');
            })
            .catch((err) => {
                setClickSubmitButton(false);
                // setFormData(intialData);
                setShowMsg('Something went wrong');
                console.log(err.message);
            });
        }

        const handleNotApplicable = (e) => {
            console.log(e.target.checked)
            setFormData({...formData,'notApplicable': e.target.checked})
        }



    return (
        <>
            {/* toggle button */}
            <Tooltip title="We are happy to receive your feedback, question or grievance. Please make sure you attach a related screenshot (if possible) for greater clarity">
                <Fab
                    component="div"
                    onClick={handleToggle}
                    size="medium"
                    variant="circular"
                    color="secondary"
                    sx={{
                        borderRadius: 0,
                        borderTopLeftRadius: '50%',
                        borderBottomLeftRadius: '50%',
                        borderTopRightRadius: '50%',
                        borderBottomRightRadius: '4px',
                        top: '85%',
                        position: 'fixed',
                        right: 10,
                        zIndex: theme.zIndex.speedDial,
                        boxShadow: theme.customShadows.secondary
                    }}
                >
                    <AnimateButton >
                        <IconButton color="inherit" size="large" disableRipple>
                            <MailIcon />
                        </IconButton>
                    </AnimateButton>
                </Fab>
            </Tooltip>

            <Drawer
                anchor="right"
                onClose={handleToggle}
                open={open}
                PaperProps={{
                    sx: {
                        width: 380,
                        height:'70%',
                        top:'10%',

                    }
                }}
            >
                {open && (
                    <PerfectScrollbar component="div">
                        <Grid container spacing={gridSpacing} sx={{ p: 3 }}>
                            <Grid item xs={12}>
                            <SubCard title="Please provide a feedback of your experience">
                                <Grid container spacing={gridSpacing} sx={{ p: 3 }}>
                                    <form onSubmit={handleSubmit} fullWidth style={{width: '100%', margin: 'auto'}}>
                                    {/* <Grid item xs={12} lg={12}  container spacing={2} alignItems="center">
                                    
                                    <TextField
                                        value={formData.name}
                                        onChange={(e) =>{handleInputChange(e)}}
                                        name="name"
                                        required
                                    fullWidth id="outlined-basic" label={'Enter Name'} />
                                        
                                    </Grid> */}
                                    <Grid item xs={12} lg={12}  container spacing={2} alignItems="center" sx={{mt: 1}}>
                                    
                                    <TextField
                                        type="text"
                                        value={formData.title}
                                        onChange={(e) =>{handleInputChange(e)}}
                                        name="title"
                                        required
                                    fullWidth id="outlined-basic" label={'Enter title'} />
                                        
                                    </Grid>
                                    {/* <Grid item . */}
                                    <Grid item xs={12} lg={12}  container spacing={2} alignItems="center" sx={{mt: 1}}>
                                    
                                        <TextField
                                            value={formData.description}
                                            onChange={(e) =>{handleInputChange(e)}}
                                            name="description"
                                        multiline
                                        rows={4} fullWidth id="outlined-basic" label={'Enter Description'} />
                                        
                                    </Grid>

                                    <Grid item xs={12} container spacing={2} alignItems="center" sx={{mt: 2}}>
                                    
                                        <input id="image_optional" type="checkbox" name="image_optional" checked={formData['notApplicable']}  onChange={(e) => handleNotApplicable(e)}  />

                                        <InputLabel for="image_optional">Attach Image</InputLabel>
                                    </Grid>
                                    <Grid item xs={12} container spacing={2} alignItems="center" sx={{mt: 0}} style={{display:!formData['notApplicable'] ? 'none' : 'block'}}>
                                        <div>

                                            <TextField name="img" type="file" id="imgInp" className="disabled" onChange={(e) =>uploadImage(e)} disabled={!formData['notApplicable']} inputProps={{accept:"image/png, image/jpg, image/jpeg" }}/>
                                        </div>
                                        
                                    
                                    </Grid>

                                    <Grid item xs={12} container spacing={2} alignItems="center" sx={{mt: 2, textAlign:'center'}}>
                                    
                                        
                                        <Button type="submit" variant="outlined" style={{margin:'auto'}}> 
                                            {clickSubmitButton ?<CircularProgress  style={{ height: "25px", width: "25px" }} /> : 'Send'}
                                        </Button>
                                    
                                    </Grid>
                                    </form>

                                    <InputLabel>{showMsg}</InputLabel>
                                </Grid>
                            </SubCard>
                            </Grid>
                          
                        </Grid>
                    </PerfectScrollbar>
                )}
              
                <Dialog open={showDialog}
                onClose={(e)=>{handleCloseDialog(e)}}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                sx={{ p: 3 }} >
<DialogTitle id="alert-dialog-title">Alert</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                <Typography variant="body2" component="span">
                                Please Uncheck Attach Image Button, If There is no image file
                                </Typography>
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions sx={{ pr: 2.5 }}>
                           
                            <Button variant="contained" size="small" onClick={(e)=>{handleCloseDialog(e)}} autoFocus>
                                Close
                            </Button>
                        </DialogActions>
                </Dialog>
            </Drawer>
        </>
    );
};

export default Feedback;
